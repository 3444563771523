import Button from 'components/atoms/Button/Button';
import Text from 'components/atoms/Text/Text';
import { DemoWebsiteLink } from 'config';
import useRenderParagraphs from 'hooks/useRenderParagraphs';
import useWindowDimensions from 'hooks/useWindowDimensions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { deviceBreakpoints } from 'Theme';
import { handleRedirectToDemoSite, TranslationType } from 'utils/utils';

import DemoItemOne from '../../../assets/demoItemOne.png';
import DemoItemThree from '../../../assets/demoItemThree.png';
import DemoItemTwo from '../../../assets/demoItemTwo.png';

const ExperienceSection: React.FC = () => {
  const { t } = useTranslation(['main']);

  const demoOneDescription = useRenderParagraphs(
    t('demoItemOneDescription', { returnObjects: true }) as TranslationType,
  );
  const demoTwoDescription = useRenderParagraphs(
    t('demoItemTwoDescription', { returnObjects: true }) as TranslationType,
  );
  const demoThreeDescription = useRenderParagraphs(
    t('demoItemThreeDescription', { returnObjects: true }) as TranslationType,
  );

  const { isMobile } = useWindowDimensions();

  const titleType = isMobile ? 'title2' : 'display2';
  const areaTitle = isMobile ? 'headline1' : 'title3';
  const areaText = isMobile ? 'caption1' : 'body1reading';
  const buttonType = isMobile ? 'label2' : 'body1normal';
  const size = isMobile ? 'small' : 'large';

  return (
    <Container>
      <Text type={titleType} weight='bold' padding='0 60px'>
        {t('ourProductsTitle')}
      </Text>
      <BoxesContainer>
        <Box color='#d9ebff'>
          <Image src={DemoItemOne} alt='Box 1' />
          <Text type={areaTitle} weight='bold'>
            {t('demoItemOneTitle')}
          </Text>
          <BoxText>
            <Text type={areaText}>{demoOneDescription}</Text>
          </BoxText>
        </Box>
        <Box color='#dafaf3'>
          <Image src={DemoItemTwo} alt='Box 2' />
          <Text type={areaTitle} weight='bold'>
            {t('demoItemTwoTitle')}
          </Text>
          <BoxText>
            <Text type={areaText}>{demoTwoDescription}</Text>
          </BoxText>
        </Box>
        <Box color='#f5f5f6'>
          <Image src={DemoItemThree} alt='Box 3' />
          <Text type={areaTitle} weight='bold'>
            {t('demoItemThreeTitle')}
          </Text>
          <BoxText>
            <Text type={areaText}>{demoThreeDescription}</Text>
          </BoxText>
        </Box>
      </BoxesContainer>
      <ButtonWrapper>
        <Button
          onClickAction={() => handleRedirectToDemoSite(DemoWebsiteLink)}
          buttonType='blue-white'
          text={t('tryNow')}
          textColor='white'
          textType={buttonType}
          padding='0px 16px'
          size={size}
          weight='bold'
        />
      </ButtonWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 112px;
  padding-bottom: 112px;

  @media only screen and ${deviceBreakpoints.mobile} {
    display: flex;
    padding-top: 40px;
    padding-bottom: 40px;
    justify-content: center;
    text-align: center;
  }
`;

const BoxesContainer = styled.div`
  display: flex;
  max-width: 1348px;
  padding-top: 80px;
  margin: auto;
  gap: 20px;
  justify-content: center;

  @media only screen and ${deviceBreakpoints.mobile} {
    max-width: 330px;
    flex-direction: column;
    padding-top: 40px;
  }
`;

const Box = styled.div`
  width: 100%;
  padding: 28px 32px 28px 32px;
  background-color: ${({ color }) => color || '#f0f0f0'};
  border-radius: 15px;
  text-align: left;
  box-sizing: border-box;

  @media only screen and ${deviceBreakpoints.mobile} {
    text-align: center;
    padding: 28px;
  }
`;

const Image = styled.img`
  width: 381px;
  height: 270px;
  padding-bottom: 32px;

  @media only screen and ${deviceBreakpoints.mobile} {
    width: 280px;
    height: 200px;
    padding-bottom: 20px;
  }
`;

const BoxText = styled.div`
  padding-bottom: 10px;
  padding-top: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

  @media only screen and ${deviceBreakpoints.mobile} {
    margin-top: 32px;
  }
`;

export default ExperienceSection;
