import React from 'react';

interface CloseIconProps {
  size?: 'small' | 'default';
}

const CloseIcon: React.FC<CloseIconProps> = ({ size = 'default' }) => {
  return size === 'default' ? (
    <svg width='30' height='22' viewBox='0 0 21 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.41 10.9999L20.7 2.70994C20.8638 2.51864 20.9494 2.27256 20.9397 2.02089C20.93 1.76921 20.8257 1.53047 20.6476 1.35238C20.4695 1.17428 20.2307 1.06995 19.9791 1.06023C19.7274 1.05051 19.4813 1.13612 19.29 1.29994L11 9.58994L2.71 1.28994C2.5217 1.10164 2.26631 0.99585 2 0.99585C1.7337 0.99585 1.47831 1.10164 1.29 1.28994C1.1017 1.47825 0.995912 1.73364 0.995912 1.99994C0.995912 2.26624 1.1017 2.52164 1.29 2.70994L9.59 10.9999L1.29 19.2899C1.18532 19.3796 1.1003 19.4899 1.04028 19.614C0.980255 19.738 0.946525 19.8732 0.941205 20.0109C0.935886 20.1486 0.959091 20.2859 1.00937 20.4143C1.05964 20.5426 1.1359 20.6591 1.23335 20.7566C1.33081 20.854 1.44736 20.9303 1.57568 20.9806C1.70401 21.0309 1.84134 21.0541 1.97906 21.0487C2.11678 21.0434 2.25191 21.0097 2.37597 20.9497C2.50004 20.8896 2.61036 20.8046 2.7 20.6999L11 12.4099L19.29 20.6999C19.4813 20.8638 19.7274 20.9494 19.9791 20.9397C20.2307 20.9299 20.4695 20.8256 20.6476 20.6475C20.8257 20.4694 20.93 20.2307 20.9397 19.979C20.9494 19.7273 20.8638 19.4812 20.7 19.2899L12.41 10.9999Z'
        fill='#888CAB'
      />
    </svg>
  ) : (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.2667 12.2667L3.7334 3.7334M12.2667 3.7334L3.7334 12.2667'
        stroke='#888CAB'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default CloseIcon;
