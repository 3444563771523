import Text from 'components/atoms/Text/Text';
import { DemoWebsiteLink } from 'config';
import FlagIcon from 'icons/Flag';
import i18n, { userLanguage } from 'locales/i18n';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { handleRedirectToDemoSite } from 'utils/utils';

interface Icon {
  key: string;
  value: React.ReactNode;
  text: string;
}

const LanguageBar: React.FC = () => {
  const { t } = useTranslation(['main']);
  const navigate = useNavigate();

  const korean: Icon = { key: 'ko', value: <FlagIcon language='ko' />, text: 'KO' };
  const english: Icon = { key: 'en', value: <FlagIcon language='en' />, text: 'EN' };

  const [currentLanguage, setCurrentLanguage] = useState<Icon>(() =>
    userLanguage === korean.key ? korean : english,
  );

  const changeLanguage = (currentLanguage: Icon) => {
    const newLanguage = currentLanguage.key === 'ko' ? english : korean;
    setCurrentLanguage(newLanguage);
    localStorage.setItem('lng', newLanguage.key);
    i18n.changeLanguage(newLanguage.key);
    window.location.reload();
  };

  useEffect(() => {
    console.log(`Language changed to: ${currentLanguage.key}`);
  }, [currentLanguage]);

  const contact = () => {
    navigate('/contact-us');
    window.location.reload();
  };

  return (
    <Bar>
      <LeftContainer>
        <Button onClick={() => handleRedirectToDemoSite(DemoWebsiteLink)}>
          <Text type='body3' textColor='white'>
            {t('demoSite')}
          </Text>
        </Button>
        <Divider />
        <Button onClick={() => contact()}>
          <Text type='body3' textColor='white'>
            {t('contact')}
          </Text>
        </Button>
      </LeftContainer>
      <RightButton onClick={() => changeLanguage(currentLanguage)}>
        <IconContainer>{currentLanguage.value}</IconContainer>
        <Text type='body3' textColor='white'>
          {currentLanguage.text}
        </Text>
      </RightButton>
    </Bar>
  );
};

const Bar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 0px;
  background-color: ${(props) => props.theme.colors.blue};
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 20px;
`;

const Button = styled.button`
  padding: 10px;
  border: none;
  background: none;
`;

const Divider = styled.div`
  width: 1px;
  height: 14px;
  opacity: 0.4;
  background: #e1e2e4;
`;

const RightButton = styled(Button)`
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-right: 20px;
  text-align: center;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 4px;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
`;

export default LanguageBar;
