import emailjs from '@emailjs/browser';
import useRenderParagraphs from 'hooks/useRenderParagraphs';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TranslationType } from 'utils/utils';

import Check from '../../../assets/check.png';
import Button from '../../../components/atoms/Button/Button';
import Input from '../../../components/atoms/Input/Input';
import Modal from '../../../components/atoms/Modal/Modal';
import Spinner from '../../../components/atoms/Spinner/Spinner';
import Text from '../../../components/atoms/Text/Text';
import TextField from '../../../components/atoms/TextField/TextField';
import { emailJSPublicKey, emailJSServiceId, emailJSTemplateId } from '../../../config';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { deviceBreakpoints } from '../../../Theme';

interface ContactFormProps {
  className?: string;
}

interface FormValue {
  name: string;
  email: string;
  message: string;
  category: string[];
  phone: string;
  companyName?: string;
}

const ContactForm: React.FC<ContactFormProps> = ({ className }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sendingWasSuccessful, setSendingWasSuccessful] = useState<boolean>(false);
  const [sendingError, setSendingError] = useState<boolean>(false);
  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const { t } = useTranslation(['contactUs']);

  const {
    control,
    handleSubmit,
    watch,
    trigger,
    formState: { errors, isValid },
  } = useForm<FormValue>({
    defaultValues: { email: '', name: '', message: '', category: [], phone: '', companyName: '' },
    mode: 'onChange',
  });

  const fieldNameValue = watch('name');
  const fieldEmailValue = watch('email');
  const fieldMessageValue = watch('message');
  const fieldPhoneValue = watch('phone');
  const fieldCompanyNameValue = watch('companyName');

  const validEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const result = t('contactTechnologies', { returnObjects: true });
  const tags: string[] =
    Array.isArray(result) && result.every((item) => typeof item === 'string') ? result : [];

  const formRef = useRef<HTMLFormElement>(null);

  // State to track checkbox status
  const [isTermsAgreed, setIsTermsAgreed] = useState(false);

  // Function to handle checkbox change
  const handleCheckboxChange = () => {
    setIsTermsAgreed(!isTermsAgreed);
    // Trigger validation to update isValid based on current form values
    console.error(errors);
    trigger();
  };

  const sendMessage = () => {
    const params = {
      name: fieldNameValue,
      email: fieldEmailValue,
      message: fieldMessageValue,
      category: selectedTags,
      phone: fieldPhoneValue,
      companyName: fieldCompanyNameValue,
    };
    setIsLoading(true);
    emailjs.send(emailJSServiceId, emailJSTemplateId, params, emailJSPublicKey).then(
      () => {
        setIsLoading(false);
        setSendingError(false);
        setSendingWasSuccessful(true);
      },
      () => {
        setSendingWasSuccessful(false);
        setSendingError(true);
        setIsLoading(false);
      },
    );
  };

  const { isMobile } = useWindowDimensions();

  useEffect(() => {
    if (modalIsVisible) {
      document.body.style.overflow = 'hidden';
      if (!isMobile) {
        document.body.style.paddingRight = '15px';
      }
    } else {
      document.body.style.overflow = 'scroll';
      document.body.style.paddingRight = '0px';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalIsVisible]);

  const handleTagClick = (tag: string) => {
    setSelectedTags((prevSelectedTags) =>
      prevSelectedTags.includes(tag)
        ? prevSelectedTags.filter((item) => item !== tag)
        : [...prevSelectedTags, tag],
    );
  };

  const titleType = isMobile ? 'headline1' : 'display2';
  const contract = isMobile ? 'label1normal' : 'body1normal';
  const submitText = isMobile ? 'body1normal' : 'label1normal';
  const contactDescription = useRenderParagraphs(
    t('contactDescription', { returnObjects: true }) as TranslationType,
  );
  const contactFormSuccess = useRenderParagraphs(
    t('contactFormSuccess', { returnObjects: true }) as TranslationType,
  );
  const contactFormSuccessMessage = useRenderParagraphs(
    t('contactFormSuccessMessage', { returnObjects: true }) as TranslationType,
  );
  const contactFormError = useRenderParagraphs(
    t('contactFormError', { returnObjects: true }) as TranslationType,
  );
  const contactFormErrorMessage = useRenderParagraphs(
    t('contactFormErrorMessage', { returnObjects: true }) as TranslationType,
  );

  return (
    <Container ref={formRef} className={className} onSubmit={handleSubmit(sendMessage)}>
      {isLoading && (
        <ContentWrapper>
          <StatusWrapper>
            <Spinner />
          </StatusWrapper>
        </ContentWrapper>
      )}

      {!sendingWasSuccessful && !sendingError && !isLoading && (
        <ContentWrapper>
          <TitleWrapper>
            <Text type={titleType} weight='bold'>
              {contactDescription}
            </Text>
          </TitleWrapper>
          <TagsWrapper className='tag-wrapper'>
            {tags.map((tag, index) => (
              <Button
                key={index}
                textType={'label1normal'}
                pressedTextColor='white'
                onClickAction={() => handleTagClick(tag)}
                text={tag}
                textColor={selectedTags.includes(tag) ? 'white' : 'blue'}
                buttonType={selectedTags.includes(tag) ? 'blue-white' : 'white-blue'}
                weight='bold'
              />
            ))}
          </TagsWrapper>

          <InputsRow>
            <InputContainer>
              <RequiredLabel>{t('contactNameTitle')}</RequiredLabel>
              <Input
                control={control}
                name='name'
                isTermsAgreed={isTermsAgreed}
                rules={{
                  required: { value: true, message: t('requiredField') },
                }}
                placeholder={t('contactNamePlaceholder')}
              />
            </InputContainer>
            <InputContainer>
              <RequiredLabel>{t('contactCompanyNameTitle')}</RequiredLabel>
              <Input
                control={control}
                name='companyName'
                isTermsAgreed={isTermsAgreed}
                rules={{
                  required: { value: true, message: t('requiredField') },
                }}
                placeholder={t('contactCompanyNamePlaceholder')}
              />
            </InputContainer>
          </InputsRow>

          <InputsRow>
            <InputContainer>
              <RequiredLabel>{t('contactEmailTitle')}</RequiredLabel>
              <Input
                control={control}
                name='email'
                isTermsAgreed={isTermsAgreed}
                rules={{
                  required: { value: true, message: t('requiredField') },
                  pattern: {
                    value: validEmail,
                    message: t('invalidEmailAddressFormat'),
                  },
                }}
                placeholder={t('contactEmailPlaceholder')}
              />
            </InputContainer>
            <InputContainer>
              <Label>{t('contactPhoneTitle')}</Label>
              <Input
                control={control}
                isTermsAgreed={isTermsAgreed}
                name='phone'
                placeholder={t('contactPhonePlaceholder')}
              />
            </InputContainer>
          </InputsRow>

          <InputsRow>
            <InputContainer>
              <Label>{t('contactTextboxTitle')}</Label>
              <TextField
                name='message'
                control={control}
                placeholder={t('contactTextboxPlaceholder')}
                rules={{
                  minLength: { value: 10, message: '10 ' + t('minLength') },
                }}
              />
            </InputContainer>
          </InputsRow>

          <TermsOfUseCheckbox>
            <InputCheckbox
              type='checkbox'
              checked={isTermsAgreed}
              onChange={() => handleCheckboxChange()}
            />
            <Text type={contract} weight='bold'>
              {t('contactTermsAndConditionsAgree')}
            </Text>
            <SeeMore onClick={() => setModalIsVisible(true)}>{t('seeMore')}</SeeMore>
            {modalIsVisible && <Modal onClose={() => setModalIsVisible(false)} />}
          </TermsOfUseCheckbox>

          <Button
            type='submit'
            text={t('contactSend')}
            size={'large'}
            textType={submitText}
            weight='bold'
            textColor='white'
            buttonType='blue-white'
            onClickAction={() => ({})}
            disabled={!isTermsAgreed || !isValid}
          />
        </ContentWrapper>
      )}

      {sendingWasSuccessful && !sendingError && !isLoading && (
        <ResponseWrapper>
          <StatusWrapper>
            <Image src={Check} />
            <Text type={isMobile ? 'title2' : 'display2'} weight='bold'>
              {contactFormSuccess}
            </Text>
            <Text type={isMobile ? 'headline2' : 'heading1'} weight='bold'>
              {contactFormSuccessMessage}
            </Text>
          </StatusWrapper>
        </ResponseWrapper>
      )}

      {!sendingWasSuccessful && sendingError && !isLoading && (
        <ResponseWrapper>
          <StatusWrapper>
            <Text type={isMobile ? 'title2' : 'display2'} weight='bold'>
              {contactFormError}
            </Text>
            <Text type={isMobile ? 'headline2' : 'heading1'} weight='bold'>
              {contactFormErrorMessage}
            </Text>
          </StatusWrapper>
        </ResponseWrapper>
      )}
    </Container>
  );
};

const Container = styled.form`
  width: 100%;
  background-color: #ffffff;
  padding-bottom: 80px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1000px;
  margin: auto;
  padding-top: 80px;

  @media only screen and ${deviceBreakpoints.mobile} {
    max-width: 336px;
    padding-top: 40px;
  }
`;

const TitleWrapper = styled.div`
  text-align: center;
`;

const TagsWrapper = styled.div`
  display: flex;
  gap: 10px;
  padding-top: 60px;
  padding-bottom: 14px;
  flex-wrap: nowrap;
  overflow-x: auto;

  @media only screen and ${deviceBreakpoints.mobile} {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 150px;
    width: 100%;
    align-items: start;
    gap: 5px;

    padding-top: 32px;
    padding-bottom: 8px;
  }
`;

const InputsRow = styled.div`
  display: flex;
  padding-top: 14px;
  padding-bottom: 14px;
  flex-wrap: wrap;
  width: 100%;
  gap: 28px;

  @media only screen and ${deviceBreakpoints.mobile} {
    flex-direction: column;
    padding-top: 8px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  @media only screen and ${deviceBreakpoints.mobile} {
    min-width: 100%;
  }
`;

const InputCheckbox = styled.input`
  width: 24px;
  height: 24px;
  color: #1a2b7c;
  padding-bottom: 2px;
  border-radius: 4px;
  margin-right: 10px;

  @media only screen and ${deviceBreakpoints.mobile} {
    vertical-align: baseline;
    margin-top: 0px;
  }
`;

const TermsOfUseCheckbox = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  @media only screen and ${deviceBreakpoints.mobile} {
    align-items: flex-start;
  }
`;

const SeeMore = styled.span`
  cursor: pointer;
  color: ${(props) => props.theme.colors.grey};
  text-decoration: underline;
  font-size: ${(props) => props.theme.fonts['label1normal'].fontSize};
  line-height: ${(props) => props.theme.fonts['label1normal'].lineHeight};
  font-weight: 400;
  letter-spacing: ${(props) => props.theme.fonts['label1normal'].letterSpacing};

  margin-left: 10px;
  &:hover {
    background: linear-gradient(277.62deg, #5d6be9 -3.88%, #55b8ff 67.51%, #32e0d6 138.9%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const Label = styled.label`
  margin-bottom: 5px;
  padding-bottom: 8px;
`;

const RequiredLabel = styled(Label)`
  margin-bottom: 5px;
  padding-bottom: 8px;
  &:after {
    content: '•';
    color: red;
    margin-left: 4px;
    font-size: 20px;
    line-height: 0;
  }
`;

const ResponseWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 80px 0px;
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
`;

const Image = styled.img`
  width: 80px; /* Adjust size as needed */
  height: 80px;
`;

export default ContactForm;
