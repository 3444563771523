import React from 'react';

interface FlagIconProps {
  language: 'en' | 'ko';
}

const FlagIcon: React.FC<FlagIconProps> = ({ language }) => {
  return language === 'ko' ? (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g mask='url(#mask0_580_27587)'>
        <circle cx='12' cy='12' r='12' fill='white' />
        <circle cx='12' cy='12' r='11.75' stroke='#70737C' strokeOpacity='0.22' strokeWidth='0.5' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.9279 13.7492C15.259 15.2704 13.7418 16.3328 11.9767 16.3328C9.59292 16.3328 7.66064 14.3955 7.66064 12.0055C7.66064 11.2907 7.83396 10.6154 8.13953 10.021C7.72754 12.1595 9.38616 13.4459 10.9186 12.8317C11.7684 12.4902 11.9311 11.9217 12.5651 11.4522C14.137 10.2893 16.2 11.5925 15.9355 13.7325C15.9325 13.7386 15.9325 13.7416 15.9279 13.7508V13.7492Z'
          fill='#162D9C'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.9702 7.67883C14.354 7.67883 16.2862 9.61611 16.2862 12.0061C16.2862 12.6203 16.1585 13.2026 15.929 13.7315C16.1935 11.593 14.1305 10.2883 12.5585 11.4513C11.9245 11.9207 11.7619 12.4908 10.912 12.8307C9.37808 13.445 7.72098 12.1585 8.13297 10.02C8.85055 8.62842 10.2994 7.67578 11.9702 7.67578V7.67883Z'
          fill='#E62E42'
        />
        <path
          d='M3.91825 9.19459L6.31725 5.58829L6.9132 5.98764L4.5142 9.59394L3.91825 9.19459ZM4.79849 9.79818L7.19749 6.19188L7.79344 6.59123L5.39444 10.1975L4.79849 9.79818ZM3 8.60777L5.399 5.00146L5.99495 5.40081L3.59595 9.00711L3 8.60777Z'
          fill='black'
        />
        <path
          d='M19.4846 9.594L17.0856 5.98769L17.6815 5.58835L20.0805 9.19465L19.4846 9.594ZM20.4028 9.00717L19.2854 7.32748L19.8814 6.92814L20.9988 8.6063L20.4028 9.00565V9.00717ZM19.0939 7.03941L18.0038 5.39935L18.5998 5L19.6913 6.64006L19.0954 7.03941H19.0939ZM18.6043 10.1961L17.493 8.52552L18.0889 8.12617L19.2003 9.79672L18.6043 10.1961ZM17.3014 8.23744L16.2053 6.58976L16.8013 6.19042L17.8989 7.83962L17.303 8.23897L17.3014 8.23744Z'
          fill='black'
        />
        <path
          d='M19.2003 14.2036L18.0889 15.8741L17.493 15.4748L18.6043 13.8027L19.2003 14.2021V14.2036ZM17.8989 16.1607L16.8013 17.8099L16.2053 17.4106L17.3014 15.7629L17.8974 16.1622L17.8989 16.1607ZM21.0003 15.394L19.8829 17.0737L19.2869 16.6744L20.4043 14.9947L21.0003 15.394ZM19.6928 17.3603L18.6013 19.0003L18.0053 18.601L19.0954 16.9609L19.6913 17.3603H19.6928ZM20.082 14.8087L18.9783 16.4686L18.3824 16.0692L19.4876 14.4094L20.0836 14.8087H20.082ZM18.7868 16.7551L17.6815 18.415L17.0856 18.0157L18.1893 16.3558L18.7852 16.7551H18.7868Z'
          fill='black'
        />
        <path
          d='M4.52421 14.4078L5.62945 16.0677L5.0335 16.467L3.92978 14.8071L4.52573 14.4078H4.52421ZM5.40445 13.8042L7.80345 17.4105L7.2075 17.8098L4.8085 14.2035L5.40445 13.8042ZM3.60596 14.9946L6.00496 18.6009L5.40901 19.0003L3.01001 15.394L3.60596 14.9946ZM5.821 16.3557L6.92473 18.0156L6.32878 18.415L5.22353 16.7551L5.81948 16.3557H5.821Z'
          fill='black'
        />
      </g>
    </svg>
  ) : (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='Component 1'>
        <circle id='Oval' cx='11.9999' cy='12.9999' r='11.9999' fill='white' />
        <path
          id='Union'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M22.2387 6.73931H11.4784V3.60889H19.4699C20.5649 4.48113 21.5042 5.54063 22.2387 6.73931ZM24 13.0001H11.4784V9.86969H23.5866C23.8556 10.8678 24 11.917 24 13.0001ZM12 25C14.8241 25 17.4199 24.0239 19.4697 22.3914H4.53023C6.58006 24.0239 9.17583 25 12 25ZM22.2384 19.2609H1.76121C1.17148 18.2986 0.714124 17.2468 0.41333 16.1305H23.5863C23.2855 17.2468 22.8282 18.2986 22.2384 19.2609Z'
          fill='#CE1126'
        />
        <path
          id='Shape'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6.65211 2.87395H5.55857C7.41954 1.68765 9.62934 1 11.9999 1V12.9999H0C0 12.4931 0.0317809 11.9938 0.0927647 11.5036L0.70204 11.0609L1.71917 11.7999L1.33063 10.6042L2.34781 9.8652H1.09054L0.749899 8.8167C0.867788 8.4996 0.998943 8.18896 1.14233 7.88517L1.71917 8.30427L1.40999 7.35267C1.5027 7.17919 1.59945 7.00824 1.70033 6.83996L2.34781 6.36957H1.99742C2.64429 5.39571 3.42925 4.52168 4.32483 3.77568L3.98921 4.80865L5.00634 4.06967L6.02347 4.80865L5.63493 3.61293L6.65211 2.87395ZM5.00645 11.0609L6.02358 11.7999L5.63504 10.6042L6.65222 9.8652H5.39495L5.00645 8.66948L4.61795 9.8652H3.36068L4.37786 10.6042L3.98932 11.7999L5.00645 11.0609ZM5.63504 7.10855L6.02358 8.30427L5.00645 7.56529L3.98932 8.30427L4.37786 7.10855L3.36068 6.36957H4.61795L5.00645 5.17385L5.39495 6.36957H6.65222L5.63504 7.10855ZM9.31048 11.0609L10.3276 11.7999L9.93907 10.6042L10.9563 9.8652H9.69898L9.31048 8.66948L8.92199 9.8652H7.66472L8.6819 10.6042L8.29335 11.7999L9.31048 11.0609ZM9.93907 7.10855L10.3276 8.30427L9.31048 7.56529L8.29335 8.30427L8.6819 7.10855L7.66472 6.36957H8.92199L9.31048 5.17385L9.69898 6.36957H10.9563L9.93907 7.10855ZM10.3276 4.80864L9.93907 3.61292L10.9563 2.87394H9.69898L9.31048 1.67822L8.92199 2.87394H7.66472L8.6819 3.61292L8.29335 4.80864L9.31048 4.06966L10.3276 4.80864Z'
          fill='#1A2B7C'
        />
        <circle
          id='Oval_2'
          cx='11.9999'
          cy='12.91'
          r='11.7499'
          stroke='#70737C'
          strokeOpacity='0.22'
          strokeWidth='0.5'
        />
      </g>
    </svg>
  );
};

export default FlagIcon;
