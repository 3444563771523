import React from 'react';
import ReactGA from 'react-ga4';
import styled from 'styled-components';

import { gaMeasurementId } from '../../config';
import ContactForm from './sections/ContactForm';
import ImageSection from './sections/ImageSection';

ReactGA.initialize(gaMeasurementId);

const ContactUsPage: React.FC = () => {
  ReactGA.send({ hitType: 'pageview', page: '/contact-us', title: 'Contact-Us' });

  return (
    <Wrapper>
      <ImageSection />
      <ContactForm />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
`;

export default ContactUsPage;
