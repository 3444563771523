import Text from 'components/atoms/Text/Text';
import useWindowDimensions from 'hooks/useWindowDimensions';
import ChevronIcon from 'icons/Chevron';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import blogLogo from '../../../assets/sns/blog.svg';
import facebookLogo from '../../../assets/sns/facebook.svg';
import instagramLogo from '../../../assets/sns/instagram.svg';

interface VerticalMenuProps {
  closeMenu: () => void;
}

const VerticalMenu: React.FC<VerticalMenuProps> = ({ closeMenu }) => {
  const { t } = useTranslation(['main']);

  const [openMenu1, setOpenMenu1] = useState(false);
  const [openMenu2, setOpenMenu2] = useState(false);

  const { isMobile } = useWindowDimensions();

  const subMenuItemType = isMobile ? 'body2normal' : 'display2';

  return (
    <VerticalMenuWrapper>
      <MenuItem onClick={() => setOpenMenu1(!openMenu1)}>
        <Text type={'text'} weight='bold'>
          {t('products')}
        </Text>
        <ChevronIconWrapper isOpen={openMenu1}>
          <ChevronIcon />
        </ChevronIconWrapper>
      </MenuItem>
      <SubMenu isOpen={openMenu1}>
        <SubMenuItem href='/products/Real-GPT' onClick={() => closeMenu()}>
          <Text type={subMenuItemType} textColor='grey' weight='bold'>
            Real-GPT
          </Text>
        </SubMenuItem>
        <SubMenuItem href='/products/Real-STT' onClick={() => closeMenu()}>
          <Text type={subMenuItemType} textColor='grey' weight='bold'>
            Real-STT
          </Text>
        </SubMenuItem>
        <SubMenuItem href='/products/Real-SID' onClick={() => closeMenu()}>
          <Text type={subMenuItemType} textColor='grey' weight='bold'>
            Real-SID
          </Text>
        </SubMenuItem>
        <SubMenuItem href='/products/Real-IoT' onClick={() => closeMenu()}>
          <Text type={subMenuItemType} textColor='grey' weight='bold'>
            Real-IoT
          </Text>
        </SubMenuItem>
        <SubMenuItem href='/products/Real-TTS' onClick={() => closeMenu()}>
          <Text type={subMenuItemType} textColor='grey' weight='bold'>
            Real-TTS
          </Text>
        </SubMenuItem>
      </SubMenu>
      <MenuItem href='/about-us' onClick={() => closeMenu()}>
        <Text type={'text'} weight='bold'>
          {t('aboutUs')}
        </Text>
      </MenuItem>
      <MenuItem onClick={() => setOpenMenu2(!openMenu2)}>
        <Text type={'text'} weight='bold'>
          {t('sns')}
        </Text>
        <ChevronIconWrapper isOpen={openMenu2}>
          <ChevronIcon />
        </ChevronIconWrapper>
      </MenuItem>
      <SubMenu isOpen={openMenu2}>
        <SubMenuItem>
          <ImageLink
            src={blogLogo}
            width={24}
            height={24}
            onClick={() => window.open('https://blog.naver.com/darwinai_100', '_blank')}
          />
        </SubMenuItem>
        <SubMenuItem>
          <ImageLink
            src={instagramLogo}
            width={24}
            height={24}
            onClick={() => window.open('https://www.instagram.com/darwinaiintelligence/', '_blank')}
          />
        </SubMenuItem>
        <SubMenuItem>
          <ImageLink
            width={24}
            height={24}
            src={facebookLogo}
            onClick={() => window.open('https://facebook.com/darwinintelligence', '_blank')}
          />
        </SubMenuItem>
      </SubMenu>
    </VerticalMenuWrapper>
  );
};

const VerticalMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 0.1px solid #fff;
`;

const MenuItem = styled.a`
  display: flex;
  justify-content: space-between; /* Ensures text and Chevron are on opposite ends */
  align-items: center;
  text-decoration: none;
  width: 100%;
  gap: 40px;
  padding: 24px 26px;
  border-bottom: 1px solid #ccc;
  margin: 0 auto;
`;

const ChevronIconWrapper = styled.div<{ isOpen: boolean }>`
  margin-left: auto;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const SubMenu = styled.div<{ isOpen: boolean }>`
  padding-left: 40px;
  max-height: ${({ isOpen }) => (isOpen ? '300px' : '0')};
  overflow: hidden;
  background-color: #f7f7f8;
  transition: max-height 0.7s ease;
`;

const SubMenuItem = styled.a`
  padding: 10px;
  background: ${(props) => props.theme.colors.lightGrey};
  display: flex;
  align-items: center;
  text-decoration: none;
`;

const ImageLink = styled.img`
  width: 32px;
  height: 32px;
  margin-left: 10px;
  cursor: pointer;
`;

export default VerticalMenu;
