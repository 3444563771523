import 'react-phone-number-input/style.css';

import React, { ChangeEvent } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input';
import styled from 'styled-components';

interface InputProps {
  placeholder: string;
  isTermsAgreed: boolean;
}

export type TextFieldProps<T extends FieldValues> = UseControllerProps<T> & InputProps;

const Input = <T extends FieldValues>(props: TextFieldProps<T>) => {
  const { name, control, rules, isTermsAgreed } = props;
  const { field, fieldState } = useController<T>({
    name,
    control,
    rules,
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    field.onChange(event.target.value);
  };

  const { i18n } = useTranslation();

  return name === 'phone' ? (
    <>
      <StyledPhoneInput
        defaultCountry='KR'
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange={(value: any) => field.onChange(value)}
        {...props}
      />
      <Error $isVisible={!!fieldState.error} $language={i18n.language}>
        {fieldState.error && fieldState.error.message}
      </Error>
    </>
  ) : (
    <>
      <StyledInput
        value={field.value || ''}
        onChange={handleChange}
        hasError={!!fieldState.error && isTermsAgreed}
        {...props}
      />
      <Error $isVisible={!!fieldState.error && isTermsAgreed} $language={i18n.language}>
        {fieldState.error && fieldState.error.message}
      </Error>
    </>
  );
};

const StyledInput = styled.input<{ hasError: boolean }>`
  border-radius: var(--Radius-m, 12px);
  border: 0.1px solid ${(props) => (props.hasError ? 'red' : props.theme.colors.borderGrey)};
  background: var(--DW-Semantic-Static-White, #ffffff);
  display: flex;
  min-height: 56px;
  padding: 8px 16px;
  align-items: center;
  flex: 1 0 0;

  font-size: ${(props) => props.theme.fonts['label1normal'].fontSize};
  line-height: ${(props) => props.theme.fonts['label1normal'].lineHeight};
  font-weight: 400;
  letter-spacing: ${(props) => props.theme.fonts['label1normal'].letterSpacing};

  &:focus {
    outline: none;
    border: 1px solid ${(props) => (props.hasError ? 'red' : props.theme.colors.blue)};
  }
`;

const Error = styled.div<{ $isVisible: boolean; $language: string }>`
  color: ${({ theme: { colors } }) => colors.red};
  font-size: ${(props) => props.theme.fonts['label1normal'].fontSize};
  line-height: ${(props) => props.theme.fonts['label1normal'].lineHeight};
  font-weight: 400;
  letter-spacing: ${(props) => props.theme.fonts['label1normal'].letterSpacing};
  color: red;
  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
  height: 16px;
  padding: 10px 0px 20px 0;
`;

const StyledPhoneInput = styled(PhoneInput)`
  .PhoneInputInput {
    border: 1px solid var(--DW-Semantic-Line-Normal-Neutral, rgba(112, 115, 124, 0.16));
    background: var(--DW-Semantic-Static-White, #ffffff);
    padding: 8px 16px;
    border-radius: var(--Radius-m, 12px);
    width: 100%;
    font-size: ${(props) => props.theme.fonts['label1normal'].fontSize};
    line-height: ${(props) => props.theme.fonts['label1normal'].lineHeight};
    font-weight: 400;
    letter-spacing: ${(props) => props.theme.fonts['label1normal'].letterSpacing};
    height: 56px;
    outline: none;

    &:focus {
      border: 1px solid ${(props) => props.theme.colors.blue};
    }
  }

  .PhoneInputCountry {
    display: flex;
    height: 56px;
    padding: 8px 16px;

    align-items: center;
    gap: 8px;
    border-radius: var(--Radius-m, 12px);

    border: 1px solid var(--DW-Semantic-Line-Normal-Neutral, rgba(112, 115, 124, 0.16));
    background: var(--DW-Semantic-Static-White, #ffffff);
  }

  .PhoneInputCountrySelect {
    border: none;
    background: transparent;
    padding-left: 10px;
    padding-right: 5px;
    cursor: pointer;
  }
`;

export default Input;
