import Text from 'components/atoms/Text/Text';
import useRenderParagraphs from 'hooks/useRenderParagraphs';
import useWindowDimensions from 'hooks/useWindowDimensions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { deviceBreakpoints } from 'Theme';
import { TranslationType } from 'utils/utils';

import Background from '../../../assets/aboutUs.png';

const ImageSection = () => {
  const { t } = useTranslation(['aboutUs']);

  const { isMobile } = useWindowDimensions();

  const areaTitle = isMobile ? 'title2' : 'display1';
  const areaText = isMobile ? 'caption1' : 'title3';
  const title = useRenderParagraphs(t('topTitle', { returnObjects: true }) as TranslationType);
  const description = useRenderParagraphs(
    t('topDescription', { returnObjects: true }) as TranslationType,
  );

  return (
    <Container>
      <Image src={Background} alt={'box'} />
      <ContentWrapper>
        <Text type={areaTitle} weight='bold' textColor='white' padding='0 0 12px 0'>
          {title}
        </Text>
        <Text type={areaText} textColor='white'>
          {description}
        </Text>
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media only screen and ${deviceBreakpoints.mobile} {
    height: 50vh;
  }
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  transform: scale(1.7);
`;

const ContentWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
  color: #ffffff;
  width: 1348px;
  margin: auto;
  border-radius: 10px;

  @media only screen and ${deviceBreakpoints.mobile} {
    width: 100%;
    padding: 46px 10px;
  }
`;

export default ImageSection;
