import React from 'react';
import { ThemeProvider } from 'styled-components';

export const deviceSizes = {
  largeDesktop: '1899px',
  desktop: '1599px',
  gridBreakPoint: '1400px',
  tablet: '1024px',
  mobile: '360px',
};

export const deviceBreakpoints = {
  largeDesktop: `(min-width: ${deviceSizes.largeDesktop})`,
  desktop: `(min-width: ${deviceSizes.desktop}) and (max-width: ${deviceSizes.largeDesktop})`,
  gridBreakPoint: `(min-width: ${deviceSizes.gridBreakPoint}) and (max-width: ${deviceSizes.desktop})`,
  tablet: `(min-width: ${deviceSizes.tablet}) and (max-width: ${deviceSizes.gridBreakPoint})`,
  mobile: `(max-width: ${deviceSizes.tablet})`,
};

const theme = {
  colors: {
    text: '#171719',
    gradient: 'linear-gradient(100deg, #007BFF 0%, #05DFAD 99.02%)',
    white: '#ffffff',
    blue: '#1a2b7c',
    grey: 'var(--DW-Semantic-Label-Alternative, rgba(122, 123, 126, 0.61));',
    lightBlack: 'var(--DW-Semantic-Label-Neutral, rgba(46, 47, 51, 0.88));',
    lightGrey: 'var(--DW-Semantic-Line-Background-Normal-Alternative, #f7f7f8)',
    shadeGrey: 'var(--DW-Semantic-Secondary-Secoondary1, #E1E4F3)',
    borderGrey: 'var(--DW-Semantic-Line-Normal-Normal, rgba(112, 115, 124, 0.22))',
  },
  fonts: {
    title: {
      fontSize: '80px',
      lineHeight: '104px',
      letterSpacing: '-0.0319px',
    },
    display1: {
      fontSize: '56px',
      lineHeight: '72px',
      letterSpacing: '-1.786px',
    },
    display2: {
      fontSize: '40px',
      lineHeight: '52px',
      letterSpacing: '-1.128px',
    },
    title1: {
      fontSize: '36px',
      lineHeight: '48px',
      letterSpacing: '-0.972px',
    },
    title2: {
      fontSize: '28px',
      lineHeight: '38px',
      letterSpacing: '-0.661px',
    },
    title3: {
      fontSize: '24px',
      lineHeight: '32px',
      letterSpacing: '-0.552px',
    },
    heading1: {
      fontSize: '22px',
      lineHeight: '30px',
      letterSpacing: '-0.427px',
    },
    heading2: {
      fontSize: '20px',
      lineHeight: '28px',
      letterSpacing: '-0.24px',
    },
    headline1: {
      fontSize: '18px',
      lineHeight: '26px',
      letterSpacing: '-0.004px',
    },
    headline2: {
      fontSize: '17px',
      lineHeight: '24px',
    },
    body1normal: {
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.091px',
    },
    body1reading: {
      fontSize: '16px',
      lineHeight: '26px',
      letterSpacing: '0.091px',
    },
    body2normal: {
      fontSize: '15px',
      lineHeight: '22px',
      letterSpacing: '0.144px',
    },
    body2reading: {
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.144px',
    },
    label1normal: {
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.203px',
    },
    label1reading: {
      fontSize: '14px',
      lineHeight: '22px',
      letterSpacing: '0.203px',
    },
    label2: {
      fontSize: '13px',
      lineHeight: '18px',
      letterSpacing: '0.252px',
    },
    caption1: {
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.302px',
    },
    caption2: {
      fontSize: '11px',
      lineHeight: '14px',
      letterSpacing: '0.342px',
    },
  },
  columns: {
    bigDesktop: {
      colOne: `${(240 / 1920) * 100}%`,
      colTwo: `${(362 / 1920) * 100}%`,
      colThree: `${(704 / 1920) * 100}%`,
      colFour: `${(948 / 1920) * 100}%`,
      colFive: `${(972 / 1920) * 100}%`,
      colSix: `${(1094 / 1920) * 100}%`,
      colSeven: `${(1558 / 1920) * 100}%`,
      colEight: `${(1680 / 1920) * 100}%`,
      colNine: '100%',
    },
    desktop: {
      colOne: `${(80 / 1600) * 100}%`,
      colTwo: `${(202 / 1600) * 100}%`,
      colThree: `${(544 / 1600) * 100}%`,
      colFour: `${(788 / 1600) * 100}%`,
      colFive: `${(812 / 1600) * 100}%`,
      colSix: `${(934 / 1600) * 100}%`,
      colSeven: `${(1398 / 1600) * 100}%`,
      colEight: `${(1520 / 1600) * 100}%`,
      colNine: '100%',
    },
    tablet: {
      colOne: `${(41 / 1024) * 100}%`,
      colTwo: `${(122 / 1024) * 100}%`,
      colThree: `${(342 / 1024) * 100}%`,
      colFour: `${(502 / 1024) * 100}%`,
      colFive: `${(522 / 1024) * 100}%`,
      colSix: `${(602 / 1024) * 100}%`,
      colSeven: `${(902 / 1024) * 100}%`,
      colEight: `${(982 / 1024) * 100}%`,
      colNine: '100%',
    },
    mobile: {
      colOne: `${(10 / 360) * 100}%`,
      colTwo: `${(110 / 360) * 100}%`,
      colThree: `${(130 / 360) * 100}%`,
      colFour: `${(230 / 360) * 100}%`,
      colFive: `${(250 / 360) * 100}%`,
      colSix: `${(350 / 360) * 100}%`,
      colSeven: '100%',
    },
  },
};

interface ThemeProps {
  children: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Theme: React.FC<ThemeProps> = ({ children }) => {
  return <ThemeProvider theme={theme}> {children}</ThemeProvider>;
};

export default Theme;
