import useRenderParagraphs from 'hooks/useRenderParagraphs';
import useWindowDimensions from 'hooks/useWindowDimensions';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import InfographicEnglishMobile from '../../assets/infographic/Infographic_SID_EN_MO.png';
import InfographicEnglish from '../../assets/infographic/Infographic_SID_EN_PC.png';
import InfographicKoreanMobile from '../../assets/infographic/Infographic_SID_KO_MO.png';
import InfographicKorean from '../../assets/infographic/Infographic_SID_KO_PC.png';
import featureIcon1 from '../../assets/productImages/realSID/feature1.png';
import featureIcon2 from '../../assets/productImages/realSID/feature2.png';
import featureIcon3 from '../../assets/productImages/realSID/feature3.png';
import featureIcon4 from '../../assets/productImages/realSID/feature4.png';
import featureIcon5 from '../../assets/productImages/realSID/feature5.png';
import featureIcon6 from '../../assets/productImages/realSID/feature6.png';
import featureIcon7 from '../../assets/productImages/realSID/feature7.png';
import featureIcon8 from '../../assets/productImages/realSID/feature8.png';
import featureIcon9 from '../../assets/productImages/realSID/feature9.png';
import HeroSIDMobile from '../../assets/productImages/realSID/Hero_SID_MO.png';
import HeroSID from '../../assets/productImages/realSID/Hero_SID_PC.png';
import reasonImage1 from '../../assets/productImages/realSID/reasons1.png';
import reasonImage2 from '../../assets/productImages/realSID/reasons2.png';
import reasonImage3 from '../../assets/productImages/realSID/reasons3.png';
import reasonImage4 from '../../assets/productImages/realSID/reasons4.png';
import reasonImage5 from '../../assets/productImages/realSID/reasons5.png';
import { gaMeasurementId } from '../../config';
import { Feature, Reason, TranslationType } from '../../utils/utils';
import DesignSection from './sections/Design';
import FeaturesSection from './sections/Features';
import HowSection from './sections/How';
import ImageSection from './sections/ImageSection';

ReactGA.initialize(gaMeasurementId);

const RealSID: React.FC = () => {
  const { t } = useTranslation(['realSID']);
  ReactGA.send({ hitType: 'pageview', page: '/products/real-sid', title: 'Real-SID' });

  const [currentLanguage, setCurrentLanguage] = useState<string>('ko');
  const { isMobile } = useWindowDimensions();

  useEffect(() => {
    const language = localStorage.getItem('lng') || 'ko';

    setCurrentLanguage(language);
  }, []);

  const banner = isMobile ? HeroSIDMobile : HeroSID;
  const image =
    currentLanguage === 'ko'
      ? isMobile
        ? InfographicKoreanMobile
        : InfographicKorean
      : isMobile
      ? InfographicEnglishMobile
      : InfographicEnglish;

  const imageSubtitle = useRenderParagraphs(
    t('subtitle', { returnObjects: true }) as TranslationType,
  );
  const imageDescription = useRenderParagraphs(
    t('description', { returnObjects: true }) as TranslationType,
  );
  const designDescription = useRenderParagraphs(
    t('designDescription', { returnObjects: true }) as TranslationType,
  );

  const features: Feature[] = [
    {
      title: t('featureTitle1'),
      description: useRenderParagraphs(
        t('featureDescription1', { returnObjects: true }) as TranslationType,
      ),
      image: featureIcon1,
    },
    {
      title: t('featureTitle2'),
      description: useRenderParagraphs(
        t('featureDescription2', { returnObjects: true }) as TranslationType,
      ),
      image: featureIcon2,
    },
    {
      title: t('featureTitle3'),
      description: useRenderParagraphs(
        t('featureDescription3', { returnObjects: true }) as TranslationType,
      ),
      image: featureIcon3,
    },
    {
      title: t('featureTitle4'),
      description: useRenderParagraphs(
        t('featureDescription4', { returnObjects: true }) as TranslationType,
      ),
      image: featureIcon4,
    },
    {
      title: t('featureTitle5'),
      description: useRenderParagraphs(
        t('featureDescription5', { returnObjects: true }) as TranslationType,
      ),
      image: featureIcon5,
    },
    {
      title: t('featureTitle6'),
      description: useRenderParagraphs(
        t('featureDescription6', { returnObjects: true }) as TranslationType,
      ),
      image: featureIcon6,
    },
    {
      title: t('featureTitle7'),
      description: useRenderParagraphs(
        t('featureDescription7', { returnObjects: true }) as TranslationType,
      ),
      image: featureIcon7,
    },
    {
      title: t('featureTitle8'),
      description: useRenderParagraphs(
        t('featureDescription8', { returnObjects: true }) as TranslationType,
      ),
      image: featureIcon8,
    },
    {
      title: t('featureTitle9'),
      description: useRenderParagraphs(
        t('featureDescription9', { returnObjects: true }) as TranslationType,
      ),
      image: featureIcon9,
    },
  ];

  const reasons: Reason[] = [
    {
      title: t('reasonsTitle1'),
      description: useRenderParagraphs(
        t('reasonsDescription1', { returnObjects: true }) as TranslationType,
      ),
      image: reasonImage1,
    },
    {
      title: t('reasonsTitle2'),
      description: useRenderParagraphs(
        t('reasonsDescription2', { returnObjects: true }) as TranslationType,
      ),
      image: reasonImage2,
    },
    {
      title: t('reasonsTitle3'),
      description: useRenderParagraphs(
        t('reasonsDescription3', { returnObjects: true }) as TranslationType,
      ),
      image: reasonImage3,
    },
    {
      title: t('reasonsTitle4'),
      description: useRenderParagraphs(
        t('reasonsDescription4', { returnObjects: true }) as TranslationType,
      ),
      image: reasonImage4,
    },
    {
      title: t('reasonsTitle5'),
      description: useRenderParagraphs(
        t('reasonsDescription5', { returnObjects: true }) as TranslationType,
      ),
      image: reasonImage5,
    },
  ];

  return (
    <Wrapper>
      <ImageSection
        title={t('title')}
        subtitle={imageSubtitle}
        description={imageDescription}
        image={banner}
      />
      <DesignSection image={image} description={designDescription} />
      <FeaturesSection features={features} title={t('featuresTitle')} />
      <HowSection reasons={reasons} title={t('reasonsTitle')} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
`;

export default RealSID;
