import React from 'react';

const DrivenIcon: React.FC = () => {
  return (
    <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='Frame' opacity='0.8'>
        <g id='Vector'>
          <path
            d='M42.8117 7.93716V26.7046C42.8117 26.7046 39.1359 31.1014 31.7921 27.7424C24.5275 24.3914 9.82412 26.7046 9.82412 26.7046V7.93716C9.82412 7.93716 24.5196 5.54469 31.7921 8.97496C39.0567 12.4132 42.8117 7.93716 42.8117 7.93716Z'
            fill='#F14742'
          />
          <path
            d='M36.0621 4.0791C37.1791 4.0791 38.0585 4.95845 38.0585 6.07547C38.0585 7.19249 37.1791 8.07184 36.0621 8.07184C34.9451 8.07184 34.0657 7.19249 34.0657 6.07547C34.0657 4.95845 34.9451 4.0791 36.0621 4.0791Z'
            fill='#74829C'
          />
          <path
            d='M16.2569 31.0142C17.3739 31.0142 18.2532 31.8936 18.2532 33.0106C18.2532 34.1276 17.3739 35.007 16.2569 35.007C15.1398 35.007 14.2605 34.1276 14.2605 33.0106C14.2605 31.8936 15.1398 31.0142 16.2569 31.0142Z'
            fill='#74829C'
          />
          <path
            d='M9.98256 44.359C9.98256 45.7263 8.87417 46.8347 7.50691 46.8347C6.13964 46.8347 5.03125 45.7263 5.03125 44.359V8.41645C5.03125 7.04918 6.13964 5.9408 7.50691 5.9408H7.98619C9.08876 5.9408 9.98256 6.8346 9.98256 7.93716V44.359Z'
            fill='#73665C'
          />
          <path d='M33.7168 36.9713H31.7204V38.9677H33.7168V36.9713Z' fill='#74829C' />
          <path
            d='M31.7204 38.9677L29.7244 38.9673V40.9637H31.7208L31.7204 38.9677Z'
            fill='#74829C'
          />
          <path
            d='M31.7208 34.9753H29.7244V36.9717L31.7204 36.9713L31.7208 34.9753Z'
            fill='#74829C'
          />
          <path
            d='M29.7244 36.9717L27.7281 36.9713V38.9677L29.7244 38.9673V36.9717Z'
            fill='#74829C'
          />
        </g>
      </g>
    </svg>
  );
};

export default DrivenIcon;
