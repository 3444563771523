import Text from 'components/atoms/Text/Text';
import ChevronIcon from 'icons/Chevron';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import blogLogo from '../../../assets/sns/blog.svg';
import facebookLogo from '../../../assets/sns/facebook.svg';
import instagramLogo from '../../../assets/sns/instagram.svg';

const SNSDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation(['main']);
  const ref = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const snsDropdownItems = [
    {
      key: 'blog',
      value: <img width={40} height={40} src={blogLogo} />,
      url: 'https://blog.naver.com/darwinai_100',
    },
    {
      key: 'instagram',
      value: <img width={40} height={40} src={instagramLogo} />,
      url: 'https://www.instagram.com/darwinaiintelligence/',
    },
    {
      key: 'facebook',
      value: <img width={40} height={40} src={facebookLogo} />,
      url: 'https://facebook.com/darwinintelligence',
    },
  ];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <DropdownContainer ref={ref}>
      <DropdownButton ref={buttonRef} onClick={toggleDropdown}>
        <Text type='body1normal' weight='bold'>
          {t('sns')}
        </Text>
        <ChevronIconWrapper isOpen={isOpen}>
          <ChevronIcon />
        </ChevronIconWrapper>
      </DropdownButton>
      <DropdownMenu isOpen={isOpen}>
        {snsDropdownItems.map((item) => (
          <DropdownMenuItem key={item.key} onClick={() => window.open(item.url, '_blank')}>
            <IconContainer>{item.value}</IconContainer>
          </DropdownMenuItem>
        ))}
      </DropdownMenu>
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownButton = styled.button`
  border: none;
  background: none;
  padding: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  gap: 10px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ChevronIconWrapper = styled.div<{ isOpen: boolean }>`
  margin-left: auto;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const DropdownMenu = styled.ul<{ isOpen: boolean }>`
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ddd;
  border-radius: var(--Radius-m, 12px);
  background: white;
  position: absolute;
  top: 60px;
  left: 0;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
`;

const DropdownMenuItem = styled.li`
  padding: 8px 20px;
  cursor: pointer;
  display: flex;
  border-radius: 8px;
  align-items: center;
  margin: 8px;

  &:hover {
    background-color: ${(props) => props.theme.colors.shadeGrey};
  }
`;

export default SNSDropdown;
