import Button from 'components/atoms/Button/Button';
import LanguageBar from 'components/molecules/LanguageBar/LanguageBar';
import LanguageDropdown from 'components/molecules/LanguageDropdown/LanguageDropdown';
import ProductsDropdown from 'components/molecules/ProductsDropdown/ProductsDropdown';
import SNSDropdown from 'components/molecules/SNSDropdown/SNSDropdown';
import VerticalMenu from 'components/molecules/VerticalMenu/VerticalMenu';
import { DemoWebsiteLink } from 'config';
import useWindowDimensions from 'hooks/useWindowDimensions';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { deviceBreakpoints } from 'Theme';
import { handleRedirectToDemoSite } from 'utils/utils';

import CloseIcon from '../../../icons/Close';
import MenuIcon from '../../../icons/Menu';
import Logo from '../../atoms/Logo/Logo';
import Text from '../../atoms/Text/Text';

const HeaderComponent: React.FC = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const navigate = useNavigate();
  const { isMobile } = useWindowDimensions();

  const { t } = useTranslation(['main']);
  const location = useLocation();

  const ref = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        (ref.current && !ref.current.contains(event.target as Node) && menuIsOpen) ||
        (buttonRef.current && buttonRef.current.contains(event.target as Node) && menuIsOpen)
      ) {
        setMenuIsOpen(false);
      } else if (
        buttonRef.current &&
        buttonRef.current.contains(event.target as Node) &&
        !menuIsOpen
      ) {
        setMenuIsOpen(true);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuIsOpen]);

  const clickLogo = () => {
    if (location.pathname === '/') {
      window.scrollTo(0, 0);
    } else {
      navigate('/');
    }
  };

  return (
    <Maindiv ref={ref}>
      {isMobile ? (
        <MobileHeader isMenuOpen={menuIsOpen}>
          <TopBar>
            <Logo onClick={() => clickLogo()} marginLeft={'0px'} size={'small'} />
            <MenuButton ref={buttonRef}>{!menuIsOpen ? <MenuIcon /> : <CloseIcon />}</MenuButton>
          </TopBar>
          {menuIsOpen && <LanguageBar />}
          {menuIsOpen && <VerticalMenu closeMenu={() => setMenuIsOpen(false)} />}
        </MobileHeader>
      ) : (
        <MenuWrapper>
          <LeftSection>
            <Logo onClick={() => clickLogo()} marginLeft={'0px'} size={'default'} />
          </LeftSection>
          <CenterSection>
            <ProductsDropdown />
            <MenuItem href='/about-us'>
              <Text type='body1normal' textColor='text' weight='bold'>
                {t('aboutUs')}
              </Text>
            </MenuItem>
            <SNSDropdown />
          </CenterSection>
          <RightSection>
            <Button
              onClickAction={() => navigate('/contact-us')}
              buttonType='white-blue'
              textColor='blue'
              size='small'
              weight='bold'
              text={t('contact')}
              textType='label2'
              padding='0px 16px'
              display={'none'}
            />
            <Button
              onClickAction={() => handleRedirectToDemoSite(DemoWebsiteLink)}
              buttonType='blue-white'
              textColor='white'
              size='small'
              weight='bold'
              text={t('demo')}
              textType='label2'
              padding='0px 16px'
              display={'none'}
            />
            <LanguageDropdown />
          </RightSection>
        </MenuWrapper>
      )}
    </Maindiv>
  );
};

const Maindiv = styled.div`
  width: 100%;
  position: sticky;
  z-index: 100;
  background-color: ${(props) => props.theme.colors.white};
  top: 0;
  left: 0;
  padding: 12px 48px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and ${deviceBreakpoints.mobile} {
    padding: 0px;
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  width: 1348px;
`;

const LeftSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  padding-right: 380px;
  align-items: center;
`;

const CenterSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const RightSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding-left: 20px;
  align-items: center;
`;

const MenuItem = styled.a`
  display: flex;
  align-items: center;
  width: auto;
  margin: 0 20px;
  cursor: pointer;
  text-decoration: none;
`;

const MobileHeader = styled.div<{ isMenuOpen: boolean }>`
  width: 100%;
  height: ${({ isMenuOpen }) => (isMenuOpen ? '100%' : 'auto')};
  position: ${({ isMenuOpen }) => (isMenuOpen ? 'fixed' : 'relative')};
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.colors.white};
  z-index: ${({ isMenuOpen }) => (isMenuOpen ? 999 : 1)};
  overflow: hidden;
`;

const MenuButton = styled.button`
  max-width: 40px;
  height: 40px;
  border: none;
  background: none;
  cursor: pointer;
`;

const TopBar = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 11px 20px;
`;

export default HeaderComponent;
