import useWindowDimensions from 'hooks/useWindowDimensions';
import React from 'react';
import styled from 'styled-components';

import Divider from '../../../assets/divider.png';
import Text from '../../../components/atoms/Text/Text';
import { deviceBreakpoints } from '../../../Theme';
import { Feature } from '../../../utils/utils';

interface Props {
  features?: Feature[];
  title?: string | null;
}

const FeaturesSection: React.FC<Props> = ({ features, title }) => {
  const { isMobile } = useWindowDimensions();

  const titleType = isMobile ? 'title2' : 'display2';
  const boxTitle = isMobile ? 'title3' : 'title3';
  const boxText = isMobile ? 'body1reading' : 'body1reading';

  return (
    <Container>
      <Text type={titleType} weight='bold' textColor='white'>
        {title}
      </Text>
      <BoxContent>
        {features?.map((feature, index) => (
          <AreaWrapper key={index}>
            <ContentWrapper>
              <Text type={boxTitle} weight='bold'>
                {feature.title}
              </Text>
              <img src={Divider} width={'48px'} height={'8px'} alt={'values-divider'} />
              <Text type={boxText}>{feature.description}</Text>
            </ContentWrapper>
            <Image src={feature.image} alt={feature.title} />
          </AreaWrapper>
        ))}
      </BoxContent>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background-color: #1a2b7c;
  padding-bottom: 112px;
  padding-top: 112px;
  text-align: center;

  @media only screen and ${deviceBreakpoints.mobile} {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const BoxContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: var(--Spacing-xl, 40px);
  max-width: 1348px;
  margin: auto;
  padding-top: 80px;

  @media only screen and ${deviceBreakpoints.mobile} {
    grid-template-columns: 1fr;
    gap: 20px;
    max-width: 336px;
    padding-top: 32px;
  }
`;

const AreaWrapper = styled.div`
  position: relative;
  background-color: #f4f4f4;
  border: 0.1px solid #ccc;
  border-radius: 16px;
  box-sizing: border-box;
  text-align: left;
  padding: 32px 28px;

  @media only screen and ${deviceBreakpoints.mobile} {
    max-width: 100%;
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 60px;
`;

const Image = styled.img`
  margin: 10px;
  display: flex;
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 64px;
  height: 64px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 99px;

  @media only screen and ${deviceBreakpoints.mobile} {
    margin: 20px 0;
  }
`;

export default FeaturesSection;
