import Text from 'components/atoms/Text/Text';
import useWindowDimensions from 'hooks/useWindowDimensions';
import React from 'react';
import styled from 'styled-components';
import { deviceBreakpoints } from 'Theme';

import { Reason } from '../../../utils/utils';

interface Props {
  reasons?: Reason[];
  title?: string | null;
}

const HowSection: React.FC<Props> = ({ reasons, title }) => {
  const { isMobile } = useWindowDimensions();

  const titleType = isMobile ? 'title2' : 'display2';
  const boxTitleType = isMobile ? 'heading1' : 'title2';
  const boxTextType = isMobile ? 'label1reading' : 'heading1';

  return (
    <ContentWrapper>
      <Text type={titleType} weight='bold'>
        {title}
      </Text>
      <BoxContainer>
        {reasons?.map((box, index) => (
          <Box key={index} reverse={index % 2 !== 0}>
            <Image src={box.image} alt={box.title} reverse={index % 2 !== 0} />
            <TextContainer>
              <Text type={boxTitleType} textColor='lightBlack' weight='bold' padding='0 0 16px 0'>
                {box.title}
              </Text>
              <Text type={boxTextType} textColor='grey'>
                {box.description}
              </Text>
            </TextContainer>
          </Box>
        ))}
      </BoxContainer>
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e1e2e4;
  align-items: center;
  padding-top: 112px;
  padding-bottom: 112px;

  @media only screen and ${deviceBreakpoints.mobile} {
    display: flex;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const BoxContainer = styled.div`
  flex: 1 1 calc(33.333% - 10px);
  justify-content: center;
  align-items: center;
  width: 1348px;
  margin: auto;
  padding-top: 80px;
  gap: 80px;

  @media only screen and ${deviceBreakpoints.mobile} {
    width: 336px;
    flex: 1 1 auto;
    padding-top: 32px;
  }
`;

const TextContainer = styled.div`
  flex: 1;
  @media only screen and ${deviceBreakpoints.mobile} {
    padding-left: 2px;
  }
`;

const Box = styled.div<{ reverse: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  border-radius: 8px;
  box-sizing: border-box;
  padding-bottom: 80px;
  &:last-child {
    padding-bottom: 0px;
  }

  @media only screen and ${deviceBreakpoints.mobile} {
    flex-direction: column;
    padding-bottom: 40px;
    align-items: start;
  }
`;

const Image = styled.img<{ reverse: boolean }>`
  width: 520px;
  height: 280px;
  border-radius: 10px;
  object-fit: cover;
  margin: ${({ reverse }) => (reverse ? '0 0 0 60px' : '0 80px 0 0')};
  @media only screen and ${deviceBreakpoints.mobile} {
    width: 100%;
    height: 176px;
    margin: 0 0 20px 0;
  }
`;

export default HowSection;
