import React from 'react';

const GoalIcon: React.FC = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48' fill='none'>
      <g opacity='0.8' clipPath='url(#clip0_725_15060)'>
        <path
          d='M7.986 0.910156C9.66548 0.910156 11.0202 2.27276 11.0202 3.94432C11.0202 5.62381 9.65756 6.97848 7.986 6.97848C6.30651 6.97848 4.95183 5.61588 4.95183 3.94432C4.95183 2.27276 6.31443 0.910156 7.986 0.910156Z'
          fill='#FFD8A8'
        />
        <path
          d='M23.9649 4.90289C25.6444 4.90289 26.999 6.2655 26.999 7.93706C26.999 9.61654 25.6444 10.9712 23.9649 10.9712C22.2854 10.9712 20.9307 9.60862 20.9307 7.93706C20.9228 6.2655 22.2854 4.90289 23.9649 4.90289Z'
          fill='#FFD8A8'
        />
        <path
          d='M39.9358 0.910156C41.6153 0.910156 42.97 2.27276 42.97 3.94432C42.97 5.62381 41.6074 6.97848 39.9358 6.97848C38.2563 6.97848 36.9017 5.61588 36.9017 3.94432C36.9017 2.27276 38.2563 0.910156 39.9358 0.910156Z'
          fill='#FFD8A8'
        />
        <path
          d='M44.9664 10.179V14.8927H34.9845V10.179C34.9845 8.42031 36.4263 6.90719 38.2563 6.90719H41.7737C43.4532 6.89926 44.9664 8.34109 44.9664 10.179Z'
          fill='#405FAF'
        />
        <path
          d='M28.9954 14.1718V18.8854H19.0136V14.1718C19.0136 12.413 20.4475 10.8999 22.2854 10.8999H25.8028C27.4744 10.892 28.9954 12.3338 28.9954 14.1718Z'
          fill='#4273F1'
        />
        <path
          d='M13.0165 10.179V14.8927H3.03468V10.179C3.03468 8.42031 4.46858 6.90719 6.30651 6.90719H9.82392C11.5034 6.89926 13.0165 8.34109 13.0165 10.179Z'
          fill='#9F5CB0'
        />
        <path
          d='M31.9503 23.9159C31.9503 27.267 31.7127 30.4675 31.2294 33.4225C28.9162 33.7393 26.5158 33.8978 23.9649 33.8978C21.414 33.8978 19.0136 33.7393 16.7003 33.4225C16.225 30.5467 15.9794 27.3541 15.9794 23.9159H1.03831C1.03831 36.6151 11.345 46.9217 24.0441 46.9217C36.7432 46.9217 47.0499 36.6151 47.0499 23.9159H31.9503Z'
          fill='#CFE7FF'
        />
        <path
          d='M16.6924 33.3353C17.9678 41.3208 20.7643 46.8346 23.9569 46.8346C11.2578 46.8346 0.951172 36.5279 0.951172 23.8288C1.03831 28.3048 7.58989 32.0598 16.6924 33.3353Z'
          fill='#3B7AD8'
        />
        <path
          d='M46.9627 23.9159C46.9627 36.6151 36.6561 46.9217 23.9569 46.9217C27.2288 46.9217 29.9461 41.4079 31.2215 33.4225C40.3319 32.0598 46.9627 28.3048 46.9627 23.9159Z'
          fill='#3B7AD8'
        />
        <path
          d='M23.0063 23.8763C23.0063 24.4276 23.4532 24.8745 24.0045 24.8745C24.5558 24.8745 25.0027 24.4276 25.0027 23.8763C25.0027 23.325 24.5558 22.8781 24.0045 22.8781C23.4532 22.8781 23.0063 23.325 23.0063 23.8763Z'
          fill='#469BE0'
        />
        <path
          d='M23.9649 47.8724C23.4024 47.8724 22.9271 47.3891 22.9271 46.8346V26.8709C22.9271 26.3084 23.4024 25.8331 23.9649 25.8331C24.5273 25.8331 25.0027 26.3084 25.0027 26.8709V46.8425C25.0027 47.397 24.5194 47.8724 23.9649 47.8724Z'
          fill='#3B7AD8'
        />
      </g>
      <defs>
        <clipPath id='clip0_725_15060'>
          <rect width='48' height='46.9622' fill='white' transform='translate(0 0.910156)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GoalIcon;
