import Button from 'components/atoms/Button/Button';
import { DemoWebsiteLink } from 'config';
import useWindowDimensions from 'hooks/useWindowDimensions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { deviceBreakpoints } from 'Theme';
import { handleRedirectToDemoSite } from 'utils/utils';

import blogLogo from '../../../assets/sns/blog.png';
import facebookLogo from '../../../assets/sns/facebook.png';
import instagramLogo from '../../../assets/sns/instagram.png';
import Logo from '../../atoms/Logo/Logo';
import Text from '../../atoms/Text/Text';

const Footer: React.FC = () => {
  const { t } = useTranslation(['main']);
  const navigate = useNavigate();
  const location = useLocation();

  const { isMobile } = useWindowDimensions();

  const titleType = isMobile ? 'title2' : 'display2';
  const contactType = isMobile ? 'label2' : 'body1normal';
  const tryType = isMobile ? 'label2' : 'body1normal';
  const size = isMobile ? 'small' : 'large';
  const addressType = isMobile ? 'label2' : 'label1normal';

  const getAddresses = () => {
    if (isMobile) {
      return (
        <>
          <Text type={addressType} textColor='grey' padding='0 0 8px 0'>
            E-Mail: darwinai@darwinai.ai
          </Text>
          <Text type={addressType} textColor='grey' padding='0 0 4px 0'>
            Tel: +82-2-568-1383
          </Text>
          <Text type={addressType} textColor='grey'>
            Fax: +82-2-568-1363
          </Text>
        </>
      );
    } else {
      return (
        <Text type='label1normal' textColor='grey'>
          E-Mail: darwinai@darwinai.ai | Tel: +82-2-568-1383 | Fax: +82-2-568-1363
        </Text>
      );
    }
  };

  return (
    <Wrapper>
      <Image>
        <ButtonWrapper>
          <Text type={titleType} textColor='white' weight='bold'>
            {t('bannerTitle')}
          </Text>
          <ButtonGroup>
            <Button
              onClickAction={() => navigate('/contact-us')}
              buttonType='transparent-white'
              text={t('contact')}
              textType={contactType}
              padding='16px 16px'
              weight='bold'
              size={size}
              textColor='white'
            />
            <Button
              onClickAction={() => handleRedirectToDemoSite(DemoWebsiteLink)}
              buttonType='white-gradient'
              text={t('tryNow')}
              textType={tryType}
              weight='bold'
              size={size}
              textColor='gradient'
            />
          </ButtonGroup>
        </ButtonWrapper>
      </Image>
      <Box>
        <LeftBox>
          <Logo
            marginLeft='0px'
            size='footer'
            onClick={() => {
              if (location.pathname === '/') {
                window.scrollTo(0, 0);
              } else {
                navigate('/');
              }
            }}
          />
          <Icon>
            <img
              width={56}
              height={56}
              src={instagramLogo}
              onClick={() =>
                window.open('https://www.instagram.com/darwinaiintelligence/', '_blank')
              }
            />
            <img
              width={56}
              height={56}
              src={blogLogo}
              onClick={() => window.open('https://blog.naver.com/darwinai_100', '_blank')}
            />
            <img
              width={56}
              height={56}
              src={facebookLogo}
              onClick={() => window.open('https://facebook.com/darwinintelligence', '_blank')}
            />
          </Icon>
        </LeftBox>
        <RightBox>
          <Text type={addressType} textColor='grey' padding='0 0 12px 0'>
            Darwin Intelligence
          </Text>
          <Text type={addressType} textColor='grey' padding='0 0 8px 0'>
            {t('officeAddress')}
          </Text>
          {getAddresses()}
          <Text type={addressType} textColor='grey' padding='28px 0px 0px 0px'>
            © 2024 Darwin Intelligence | All Rights Reserved
          </Text>
        </RightBox>
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex-direction: column;

  @media only screen and ${deviceBreakpoints.mobile} {
    display: flex;
    justify-content: center;
    text-align: center;
  }
`;

const Image = styled.div`
  width: 100%;
  background: linear-gradient(100deg, #007bff 0%, #05dfad 99.02%);
`;

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1348px;
  margin: auto;
  padding: 60px 10px;
  flex-shrink: 0;

  @media only screen and ${deviceBreakpoints.mobile} {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    max-width: 350px;
  }
`;

const LeftBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @media only screen and ${deviceBreakpoints.mobile} {
    text-align: center;
  }
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 40px;
  gap: var(--Spacing-ml, 20px);

  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const RightBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 120px;
  text-align: right;
  padding-right: 60px;

  @media only screen and ${deviceBreakpoints.mobile} {
    text-align: center;
    padding-right: 0px;
    padding-top: 28px;
    height: unset;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 16px;
  padding-top: 21px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 88px;
  padding-bottom: 88px;

  @media only screen and ${deviceBreakpoints.mobile} {
    padding: 71px;
  }
`;

export default Footer;
