import React from 'react';

const SustainableIcon: React.FC = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='48' height='51' viewBox='0 0 48 51' fill='none'>
      <g opacity='0.8' clipPath='url(#clip0_725_25893)'>
        <path
          d='M23.9627 8.33745C23.3748 8.33745 22.878 7.84064 22.878 7.25275V1.99486C22.878 1.40696 23.3748 0.910156 23.9627 0.910156C24.5506 0.910156 25.0474 1.41525 25.0474 1.99486V7.25275C25.0474 7.84064 24.5424 8.33745 23.9627 8.33745Z'
          fill='#3C3B3D'
        />
        <path
          d='M38.1549 48.1567L24.5423 38.7256C24.4595 38.6428 24.3767 38.6428 24.2939 38.6428H24.0455C23.9627 38.6428 23.8799 38.6428 23.7971 38.7256C23.7971 38.7256 23.7143 38.7256 23.7143 38.8084C23.7143 38.8084 23.6315 38.8084 23.6315 38.8912L9.77057 48.1567C9.26548 48.4879 9.18268 49.1586 9.52217 49.5726C9.68777 49.9038 10.019 49.9866 10.3585 49.9866C10.5241 49.9866 10.7725 49.9038 10.9464 49.821L22.8863 41.5574V48.9847C22.8863 49.5726 23.3831 50.0694 23.971 50.0694C24.5589 50.0694 25.0557 49.5643 25.0557 48.9847V41.5657L36.9129 49.912C37.0785 50.0777 37.3269 50.0777 37.5008 50.0777C37.832 50.0777 38.1715 49.9121 38.3371 49.6636C38.7345 49.1586 38.5689 48.4961 38.1549 48.1567Z'
          fill='#3C3B3D'
        />
        <path
          d='M46 7.25275C47.1046 7.25275 48 8.14818 48 9.25275V38.6466C48 39.7511 47.1046 40.6466 46 40.6466H2C0.895429 40.6466 0 39.7511 0 38.6466V9.25275C0 8.14818 0.895431 7.25275 2 7.25275H46Z'
          fill='#D7DEED'
        />
        <path d='M16.6928 11.4259H4.17319V23.9455H16.6928V11.4259Z' fill='#D7DEED' />
        <path
          d='M4.17319 17.6857C4.17319 21.1429 6.9758 23.9455 10.433 23.9455C13.8902 23.9455 16.6928 21.1429 16.6928 17.6857C16.6928 14.2285 13.8902 11.4259 10.433 11.4259C6.9758 11.4259 4.17319 14.2285 4.17319 17.6857Z'
          fill='#75B0E7'
        />
        <path
          d='M43.8268 12.5106H21.8761C21.2882 12.5106 20.7914 12.0055 20.7914 11.4259C20.7914 10.838 21.2882 10.3412 21.8761 10.3412H43.8351C44.423 10.3412 44.9198 10.838 44.9198 11.4259C44.8287 12.0138 44.3319 12.5106 43.8268 12.5106Z'
          fill='#4599EC'
        />
        <path
          d='M43.8268 16.6921H21.8761C21.2882 16.6921 20.7914 16.187 20.7914 15.6074C20.7914 15.0195 21.2882 14.5227 21.8761 14.5227H43.8351C44.423 14.5227 44.9198 15.0278 44.9198 15.6074C44.8287 16.187 44.3319 16.6921 43.8268 16.6921Z'
          fill='#4599EC'
        />
        <path
          d='M43.8268 20.8653H21.8761C21.2882 20.8653 20.7914 20.3602 20.7914 19.7806C20.7914 19.1927 21.2882 18.6959 21.8761 18.6959H43.8351C44.423 18.6959 44.9198 19.1927 44.9198 19.7806C44.8287 20.3602 44.3319 20.8653 43.8268 20.8653Z'
          fill='#4599EC'
        />
        <path
          d='M43.8268 25.0385H21.8761C21.2882 25.0385 20.7914 24.5417 20.7914 23.9538C20.7914 23.3659 21.2882 22.8691 21.8761 22.8691H43.8351C44.423 22.8691 44.9198 23.3659 44.9198 23.9538C44.8287 24.5334 44.3319 25.0385 43.8268 25.0385Z'
          fill='#4599EC'
        />
        <path
          d='M43.8268 29.2117H4.17319C3.5853 29.2117 3.08849 28.7149 3.08849 28.127C3.08849 27.5391 3.59358 27.0423 4.17319 27.0423H43.8268C44.4147 27.0423 44.9115 27.5391 44.9115 28.127C44.8287 28.7066 44.3319 29.2117 43.8268 29.2117Z'
          fill='#4599EC'
        />
        <path
          d='M43.8268 33.3849H4.17319C3.5853 33.3849 3.08849 32.8881 3.08849 32.3002C3.08849 31.7123 3.59358 31.2155 4.17319 31.2155H43.8268C44.4147 31.2155 44.9115 31.7123 44.9115 32.3002C44.8287 32.8798 44.3319 33.3849 43.8268 33.3849Z'
          fill='#4599EC'
        />
        <path
          d='M43.8268 37.5581H4.17319C3.5853 37.5581 3.08849 37.0613 3.08849 36.4734C3.08849 35.8938 3.59358 35.3887 4.17319 35.3887H43.8268C44.4147 35.3887 44.9115 35.8938 44.9115 36.4734C44.8287 37.0613 44.3319 37.5581 43.8268 37.5581Z'
          fill='#4599EC'
        />
      </g>
      <defs>
        <clipPath id='clip0_725_25893'>
          <rect width='48' height='49.1675' fill='white' transform='translate(0 0.910156)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SustainableIcon;
