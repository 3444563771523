import React from 'react';
import ReactGA from 'react-ga4';
import styled from 'styled-components';

import { gaMeasurementId } from '../../config';
import AreasSection from './sections/Areas';
import ExperienceSection from './sections/Experience';
import ImageSection from './sections/ImageSection';
import PartnersSection from './sections/Partners';
import ProductsSection from './sections/Products';

ReactGA.initialize(gaMeasurementId);

const MainPage: React.FC = () => {
  ReactGA.send({ hitType: 'pageview', page: '/', title: 'Homepage' });

  return (
    <Wrapper>
      <ImageSection />
      <ExperienceSection />
      <ProductsSection />
      <AreasSection />
      <PartnersSection />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
`;

export default MainPage;
