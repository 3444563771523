import useRenderParagraphs from 'hooks/useRenderParagraphs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TranslationType } from 'utils/utils';

import CallCenter from '../../../assets/CallCenter.png';
import Financial from '../../../assets/Financial.png';
import Media from '../../../assets/Media.png';
import Podcast from '../../../assets/Podcast.png';
import Sales from '../../../assets/Sales.png';
import VideoGames from '../../../assets/VideoGames.png';
import Text from '../../../components/atoms/Text/Text';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { deviceBreakpoints } from '../../../Theme';

const AreasSection = () => {
  const { t } = useTranslation(['main']);

  const { isMobile } = useWindowDimensions();

  const titleType = isMobile ? 'title2' : 'display2';
  const descriptionType = isMobile ? 'label1normal' : 'headline1';
  const areaTitle = isMobile ? 'heading1' : 'title3';
  const areaText = isMobile ? 'label1reading' : 'body1reading';

  const areas = [
    {
      image: Financial,
      title: t('areaOneTitle'),
      description: useRenderParagraphs(
        t('areaOneDescription', { returnObjects: true }) as TranslationType,
      ),
      corner: 'top-left',
      mobile_corner: 'top',
    },
    {
      image: Sales,
      title: t('areaTwoTitle'),
      description: useRenderParagraphs(
        t('areaTwoDescription', { returnObjects: true }) as TranslationType,
      ),
      corner: 'top-middle',
    },
    {
      image: Media,
      title: t('areaThreeTitle'),
      description: useRenderParagraphs(
        t('areaThreeDescription', { returnObjects: true }) as TranslationType,
      ),
      corner: 'top-right',
    },
    {
      image: CallCenter,
      title: t('areaFourTitle'),
      description: useRenderParagraphs(
        t('areaFourDescription', { returnObjects: true }) as TranslationType,
      ),
      corner: 'bottom-left',
    },
    {
      image: VideoGames,
      title: t('areaFiveTitle'),
      description: useRenderParagraphs(
        t('areaFiveDescription', { returnObjects: true }) as TranslationType,
      ),
      corner: 'bottom-middle',
    },
    {
      image: Podcast,
      title: t('areaSixTitle'),
      description: useRenderParagraphs(
        t('areaSixDescription', { returnObjects: true }) as TranslationType,
      ),
      corner: 'bottom-right',
      mobile_corner: 'bottom',
    },
  ];

  const areasDescription = useRenderParagraphs(
    t('areasDescription', { returnObjects: true }) as TranslationType,
  );

  return (
    <Container>
      <Text type={titleType} weight='bold' textColor='white' padding='0 50px'>
        {t('areasTitle')}
      </Text>
      <DescriptionWrapper>
        <Text type={descriptionType} weight='bold' textColor='white'>
          {areasDescription}
        </Text>
      </DescriptionWrapper>
      <BoxContent>
        {areas.map((area, index) => (
          <Area
            key={index}
            corner={area.corner}
            mobile_corner={area.mobile_corner ? area.mobile_corner : ''}
          >
            <Image src={area.image} alt={area.title} />
            <Text type={areaTitle} textColor='text' weight='bold' padding='0 0 16px 0'>
              {area.title}
            </Text>
            <Text type={areaText} textColor='text'>
              {area.description}
            </Text>
          </Area>
        ))}
      </BoxContent>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background-color: #1a2b7c;
  padding-bottom: 112px;
  padding-top: 112px;
  text-align: center;
  justify-content: center;

  @media only screen and ${deviceBreakpoints.mobile} {
    padding-top: 40px;
    padding-bottom: 40px;
    justify-content: center;
  }
`;

const BoxContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1348px;
  margin: auto;

  @media only screen and ${deviceBreakpoints.mobile} {
    width: 340px;
    flex-direction: column;
  }
`;

const Area = styled.div<{ corner: string; mobile_corner: string }>`
  flex: 1 1 calc(33.333%);
  background-color: #f4f4f4;
  padding: 60px 40px;
  border: 0.1px solid #ccc;
  border-top-left-radius: ${(props) => (props.corner === 'top-left' ? '16px' : '0')};
  border-top-right-radius: ${(props) => (props.corner === 'top-right' ? '16px' : '0')};
  border-bottom-left-radius: ${(props) => (props.corner === 'bottom-left' ? '16px' : '0')};
  border-bottom-right-radius: ${(props) => (props.corner === 'bottom-right' ? '16px' : '0')};
  box-sizing: border-box;
  text-align: left;

  @media only screen and ${deviceBreakpoints.mobile} {
    border: none; /* Removes all borders */
    border-bottom: 1px solid var(--DW-Semantic-Line-Normal-Normal, rgba(112, 115, 124, 0.22)); /* Adds border only to the bottom */

    border-top-left-radius: ${(props) => (props.mobile_corner === 'top' ? '16px' : '0')};
    border-top-right-radius: ${(props) => (props.mobile_corner === 'top' ? '16px' : '0')};
    border-bottom-left-radius: ${(props) => (props.mobile_corner === 'bottom' ? '16px' : '0')};
    border-bottom-right-radius: ${(props) => (props.mobile_corner === 'bottom' ? '16px' : '0')};

    flex: 1 1 auto;
    padding: 20px;
  }
`;

const DescriptionWrapper = styled.div`
  text-align: center;
  padding-bottom: 80px;
  max-width: 1348px;
  padding-top: 32px;
  margin: auto;

  @media only screen and ${deviceBreakpoints.mobile} {
    padding-bottom: 32px;
    padding-top: 12px;
    max-width: 336px;
  }
`;

const Image = styled.img`
  width: 60px;
  height: 60px;
  margin-bottom: 28px;
  @media only screen and ${deviceBreakpoints.mobile} {
    width: 60px;
    height: 60px;
  }
`;

export default AreasSection;
