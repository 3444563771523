import React from 'react';

const CollaborativeIcon: React.FC = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='44' height='45' viewBox='0 0 44 45' fill='none'>
      <g opacity='0.8' clipPath='url(#clip0_725_8143)'>
        <path
          d='M44 18.754C44 8.93246 36.052 0.910156 26.1561 0.910156C16.2602 0.910156 8.38662 8.93246 8.38662 18.754C8.38662 28.5756 16.3346 36.5979 26.1561 36.5979C28.2528 36.5979 30.2007 36.2261 32.0744 35.6239L43.9926 40.3451L38.7435 31.4232C41.9777 28.122 44 23.6983 44 18.754Z'
          fill='#313A52'
        />
        <path
          d='M26.1636 6.60532C19.9405 6.60532 14.9219 11.6313 14.9219 17.847C14.9219 24.0626 19.9405 29.0886 26.1636 29.0886C32.3866 29.0886 37.4052 24.07 37.4052 17.847C37.4052 11.6239 32.3866 6.60532 26.1636 6.60532Z'
          fill='#65B2FE'
        />
        <path
          d='M26.1636 22.5012V29.096C24.2156 29.096 22.5651 26.2484 21.8141 22.1964C23.1599 22.3451 24.6617 22.5012 26.1636 22.5012Z'
          fill='#365A77'
        />
        <path
          d='M37.4052 17.8544C37.4052 24.0774 32.3866 29.096 26.1636 29.096C28.1115 29.096 29.7621 26.2484 30.513 22.1964C34.5576 21.4529 37.4052 19.8023 37.4052 17.8544Z'
          fill='#365A77'
        />
        <path
          d='M26.1636 6.60532C32.3866 6.60532 37.4052 11.6313 37.4052 17.847C37.4052 15.899 34.5576 14.2484 30.5056 13.4975C29.8364 9.45291 28.1115 6.60532 26.1636 6.60532Z'
          fill='#365A77'
        />
        <path
          d='M30.513 13.5049C30.7361 14.8507 30.8848 16.2782 30.8848 17.8544C30.8848 19.3563 30.7361 20.8507 30.513 22.2038C29.1599 22.4269 27.7398 22.5756 26.1636 22.5756V13.1332C27.7323 13.1258 29.1599 13.2819 30.513 13.5049Z'
          fill='#365A77'
        />
        <path
          d='M26.1636 6.60532V13.1258C24.6617 13.1258 23.1673 13.2745 21.8141 13.4975C22.5651 9.45291 24.2156 6.60532 26.1636 6.60532Z'
          fill='#365A77'
        />
        <path
          d='M21.8885 13.505C21.6654 14.8507 21.5167 16.2782 21.5167 17.8544C21.5167 19.3563 21.6654 20.8507 21.8885 22.2038C17.8439 21.5273 14.9888 19.8023 14.9888 17.8544C14.9145 15.899 17.7621 14.1815 21.8885 13.505Z'
          fill='#365A77'
        />
        <path
          d='M0.899628 43.5644C0.60223 43.5644 0.371747 43.4157 0.223048 43.267C0 42.9696 -0.0743494 42.5161 0.148699 42.2187L5.01859 33.899C1.79926 30.4417 0 25.9436 0 21.1481C0 15.0737 2.92193 9.37856 7.87361 5.85439C8.3197 5.557 8.84758 5.63135 9.14498 6.07744C9.44238 6.52354 9.36803 7.05142 8.92193 7.34882C4.49814 10.4938 1.79926 15.6685 1.79926 21.0663C1.79926 25.5644 3.52416 29.8395 6.74349 32.9845C7.04089 33.2819 7.11524 33.7354 6.89219 34.1072L3.07063 40.702L12.4387 36.9548C12.6617 36.8804 12.8922 36.8804 13.1152 36.9548C14.9145 37.557 16.7881 37.9287 18.7361 37.9287C19.2639 37.9287 19.71 38.3823 19.71 38.9027C19.71 39.4306 19.2565 39.8767 18.7361 39.8767C16.7138 39.8767 14.7658 39.5793 12.8104 38.9027L1.27138 43.5644C1.12268 43.5644 0.973978 43.5644 0.899628 43.5644Z'
          fill='#232B3F'
        />
      </g>
      <defs>
        <clipPath id='clip0_725_8143'>
          <rect width='44' height='43.1747' fill='white' transform='translate(0 0.910156)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CollaborativeIcon;
