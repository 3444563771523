import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { deviceBreakpoints } from 'Theme';

import Background from '../../../assets/contactBanner.png';

const ImageSection = () => {
  const { t } = useTranslation(['contactUs']);

  return (
    <Container>
      <Image src={Background} alt={'box'} />
      <ContentWrapper>
        <MainTitleWrapper>{t('topTitle')}</MainTitleWrapper>
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media only screen and ${deviceBreakpoints.mobile} {
    height: 30vh;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ContentWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
  color: #ffffff;
  width: 1348px;
  maring: auto;
  border-radius: 10px;

  @media only screen and ${deviceBreakpoints.mobile} {
    width: 300px;
  }
`;

const MainTitleWrapper = styled.div`
  font-size: 56px;
  font-weight: 700;
  line-height: 128.6%;
  letter-spacing: -1.786px;
  margin-bottom: 30px;

  @media only screen and ${deviceBreakpoints.mobile} {
    font-size: 28px;
    font-weight: 700;
    line-height: 135.8%;
    letter-spacing: -0.661px;
  }
`;

export default ImageSection;
