import React, { ChangeEvent } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import styled from 'styled-components';

import { deviceBreakpoints } from '../../../Theme';

interface TextFieldProps {
  placeholder: string;
}

type FieldProps<T extends FieldValues> = UseControllerProps<T> & TextFieldProps;

const TextField = <T extends FieldValues>(props: FieldProps<T>) => {
  const { name, control, rules, ...rest } = props;
  const { field, fieldState } = useController<T>({
    name,
    control,
    rules,
  });

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    field.onChange(event.target.value);
  };

  return (
    <>
      <StyledTextField
        value={field.value || ''}
        onChange={handleChange}
        hasError={!!fieldState.error}
        {...rest}
      />
      <Error $isVisible={!!fieldState.error}>{fieldState.error && fieldState.error.message}</Error>
    </>
  );
};

const StyledTextField = styled.textarea<{ hasError: boolean }>`
  resize: none;
  border-radius: var(--Radius-m, 12px);

  border: 0.1px solid ${(props) => (props.hasError ? 'red' : props.theme.colors.borderGrey)};
  background: var(--DW-Semantic-Static-White, #ffffff);
  min-height: 200px;
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  font-size: ${(props) => props.theme.fonts['label1normal'].fontSize};
  line-height: ${(props) => props.theme.fonts['label1normal'].lineHeight};
  font-weight: 400;
  letter-spacing: ${(props) => props.theme.fonts['label1normal'].letterSpacing};

  &:focus {
    outline: none;
    border: 1px solid ${(props) => (props.hasError ? 'red' : props.theme.colors.blue)};
  }

  @media only screen and ${deviceBreakpoints.mobile} {
    min-height: 140px;
  }
`;

const Error = styled.div<{ $isVisible: boolean }>`
  color: ${({ theme: { colors } }) => colors.red};
  font-size: ${(props) => props.theme.fonts['label1normal'].fontSize};
  line-height: ${(props) => props.theme.fonts['label1normal'].lineHeight};
  font-weight: 400;
  letter-spacing: ${(props) => props.theme.fonts['label1normal'].letterSpacing};
  color: red;
  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
  padding: 10px 0px 20px 0px;
`;

export default TextField;
