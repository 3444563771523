import Text from 'components/atoms/Text/Text';
import useWindowDimensions from 'hooks/useWindowDimensions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { deviceBreakpoints } from 'Theme';

import DeepgramIcon from '../../../assets/aboutUs/deepgram.png';
import PhonexiaIcon from '../../../assets/aboutUs/phonexia.png';
import Deepgram from '../../../assets/deepgram.png';
import Phonexia from '../../../assets/phonexia.png';

const PartnersSection: React.FC = () => {
  const { t } = useTranslation(['main']);

  const { isMobile } = useWindowDimensions();

  const partnerTitle = isMobile ? 'title2' : 'display2';
  const areaTitle = isMobile ? 'headline2' : 'headline2';
  const areaText = isMobile ? 'body1normal' : 'body1normal';
  const quoteText = isMobile ? 'label2' : 'body1reading';

  const partners = [
    {
      name: 'Paul Morris',
      role: 'Global Sales Manager, Phonexia',
      quote: t('phonexiaQuote'),
      image: Phonexia,
      icon: PhonexiaIcon,
    },
    {
      name: 'Chris Dyer',
      role: 'VP of Sales, Deepgram',
      quote: t('deepgramQuote'),
      image: Deepgram,
      icon: DeepgramIcon,
    },
  ];

  return (
    <Container>
      <Text type={partnerTitle} weight='bold'>
        {t('partnersTitle')}
      </Text>
      <BoxesContainer>
        {partners.map((partner, index) => (
          <Box key={index}>
            <Image src={partner.image} alt='Box 1' />
            <Text type={areaTitle} weight='bold'>
              {partner.name}
            </Text>
            <Text type={areaText}>{partner.role}</Text>
            <BoxText>
              <Text type={quoteText}>{partner.quote}</Text>
            </BoxText>
            <LowerImage src={partner.icon} />
          </Box>
        ))}
      </BoxesContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding-bottom: 112px;
  padding-top: 112px;
  flex-direction: column;
  align-items: center;
  background-color: #f7f8f9;

  @media only screen and ${deviceBreakpoints.mobile} {
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
    padding-top: 40px;
  }
`;

const BoxesContainer = styled.div`
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  max-width: 1348px;
  padding-top: 80px;
  margin: auto;

  @media only screen and ${deviceBreakpoints.mobile} {
    display: flex;
    width: 336px;
    gap: 10px;
    flex-direction: column;
    padding-top: 32px;
  }
`;

const Box = styled.div`
  border-radius: 16px;
  border: 1px solid #e1e2e4;
  display: flex;
  padding: 32px 28px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  min-height: 502px;

  @media only screen and ${deviceBreakpoints.mobile} {
    min-height: unset; /* Remove min-height on mobile */
  }
`;

const Image = styled.img`
  width: 120px;
  height: 120px;
  border-radius: var(--Radius-Sharp, 120px);
  margin-bottom: 16px;
`;

const BoxText = styled.div`
  padding-top: 40px;
  padding-bottom: 32px;
  flex-shrink: 0;
  align-self: stretch;
  height: 210px;

  @media only screen and ${deviceBreakpoints.mobile} {
    padding: 20px 0px;
    height: auto; /* Remove fixed height on mobile */
  }
`;

const LowerImage = styled.img`
  display: flex;
  width: 300px;
  height: 90px;
  justify-content: center;
  align-items: center;

  @media only screen and ${deviceBreakpoints.mobile} {
    width: 200px;
    height: 60px;
  }
`;

export default PartnersSection;
