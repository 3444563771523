import React from 'react';

const InnovativeIcon: React.FC = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='48' height='53' viewBox='0 0 48 53' fill='none'>
      <g opacity='0.8' clipPath='url(#clip0_725_15394)'>
        <path
          d='M38.6605 49.871H23.9185C23.3039 49.871 22.7845 49.343 22.7845 48.737C22.7845 48.1224 23.3039 47.603 23.9185 47.603H38.6691C40.1494 47.603 41.3786 46.3825 41.3786 44.8936V36.6873C41.3786 36.0726 41.898 35.5533 42.5126 35.5533C43.1272 35.5533 43.6466 36.0813 43.6466 36.6873V44.8936C43.5514 47.603 41.3699 49.871 38.6605 49.871Z'
          fill='#D7DEED'
        />
        <path
          d='M41.4565 29.3639C40.8419 29.3639 40.3225 28.8445 40.3225 28.2299V19.5042C40.3225 15.1414 38.5739 11.0382 35.5182 7.89591C32.4624 4.84018 28.2727 3.09158 23.9099 3.09158C14.9245 3.09158 7.50591 10.4236 7.50591 19.5042V28.2299C7.50591 28.8445 6.97787 29.3639 6.37192 29.3639C5.75731 29.3639 5.23792 28.8445 5.23792 28.2299V19.5042C5.23792 9.2896 13.5308 0.910156 23.8233 0.910156C28.8008 0.910156 33.4233 2.83189 36.9119 6.32044C40.4004 9.80898 42.3221 14.5267 42.3221 19.409V28.1347C42.5905 28.8359 42.0711 29.3639 41.4565 29.3639Z'
          fill='#D7DEED'
        />
        <path
          d='M7.67904 28.2299V36.9556H5.49762C4.27706 36.9556 3.22963 36.4362 2.44189 35.6485C1.65415 34.8607 1.13477 33.7267 1.13477 32.5928C1.13477 30.1516 3.0565 28.2299 5.49762 28.2299H7.67904Z'
          fill='#313A52'
        />
        <path
          d='M42.5905 28.2299C45.0316 28.2299 46.9533 30.1516 46.9533 32.5928C46.9533 33.8133 46.434 34.8607 45.6462 35.6485C44.8585 36.4362 43.7245 36.9556 42.5905 36.9556H40.4091V28.2299H42.5905Z'
          fill='#313A52'
        />
        <path
          d='M24.0051 9.72242C30.03 9.72242 34.9122 14.6047 34.9122 20.6295C34.9122 26.6544 30.03 31.5367 24.0051 31.5367C17.9802 31.5367 13.098 26.6544 13.098 20.6295C13.0893 14.6133 17.9802 9.72242 24.0051 9.72242Z'
          fill='#65B2FE'
        />
        <path
          d='M34.9122 20.6382H29.4154C29.4154 14.6133 26.9743 9.73108 23.9185 9.73108C30.0213 9.72242 34.9122 14.5267 34.9122 20.6382Z'
          fill='#469BE0'
        />
        <path
          d='M24.0051 20.6382H29.5019C29.5019 26.6631 27.0608 31.5453 24.0051 31.5453V20.6382Z'
          fill='#469BE0'
        />
        <path
          d='M18.5948 20.6382C18.5948 26.6631 21.0359 31.5453 24.0917 31.5453C18.0668 31.5453 13.1845 26.6544 13.1845 20.6382H18.5948Z'
          fill='#469BE0'
        />
        <path
          d='M24.0051 9.72242L24.0051 20.6295H18.5083C18.5948 14.5267 20.9494 9.72242 24.0051 9.72242Z'
          fill='#469BE0'
        />
        <path
          d='M30.5494 36.9989C30.5494 37.6013 31.0377 38.0896 31.6401 38.0896C32.2425 38.0896 32.7308 37.6013 32.7308 36.9989C32.7308 36.3965 32.2425 35.9082 31.6401 35.9082C31.0377 35.9082 30.5494 36.3965 30.5494 36.9989Z'
          fill='#74829C'
        />
        <path
          d='M25.1391 36.9989C25.1391 37.6013 25.6274 38.0896 26.2298 38.0896C26.8322 38.0896 27.3205 37.6013 27.3205 36.9989C27.3205 36.3965 26.8322 35.9082 26.2298 35.9082C25.6274 35.9082 25.1391 36.3965 25.1391 36.9989Z'
          fill='#74829C'
        />
        <path
          d='M19.6336 36.9989C19.6336 37.6013 20.1219 38.0896 20.7243 38.0896C21.3267 38.0896 21.815 37.6013 21.815 36.9989C21.815 36.3965 21.3267 35.9082 20.7243 35.9082C20.1219 35.9082 19.6336 36.3965 19.6336 36.9989Z'
          fill='#74829C'
        />
        <path
          d='M14.2233 36.9989C14.2233 37.6013 14.7116 38.0896 15.314 38.0896C15.9164 38.0896 16.4047 37.6013 16.4047 36.9989C16.4047 36.3965 15.9164 35.9082 15.314 35.9082C14.7116 35.9082 14.2233 36.3965 14.2233 36.9989Z'
          fill='#74829C'
        />
        <path
          d='M24.9573 49.871V52.0524H16.2316V49.871C16.2316 48.6505 16.751 47.603 17.5387 46.8153C18.3265 46.0276 19.4605 45.5082 20.5945 45.5082C23.0442 45.5082 24.9573 47.4299 24.9573 49.871Z'
          fill='#313A52'
        />
      </g>
      <defs>
        <clipPath id='clip0_725_15394'>
          <rect width='48' height='51.1423' fill='white' transform='translate(0 0.910156)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InnovativeIcon;
