import Text from 'components/atoms/Text/Text';
import ChevronIcon from 'icons/Chevron';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const ProductsDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation(['main']);
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const productsDropdownItems = [
    { key: 'real-gpt', value: 'Real-GPT' },
    { key: 'real-stt', value: 'Real-STT' },
    { key: 'real-sid', value: 'Real-SID' },
    { key: 'real-iot', value: 'Real-IoT' },
    { key: 'real-tts', value: 'Real-TTS' },
  ];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const openProduct = (path: string) => {
    navigate('/products/' + path);
    setIsOpen(false);
  };

  return (
    <DropdownContainer ref={ref}>
      <DropdownButton ref={buttonRef} onClick={toggleDropdown}>
        <Text type='body1normal' weight='bold'>
          {t('products')}
        </Text>
        <ChevronIconWrapper isOpen={isOpen}>
          <ChevronIcon />
        </ChevronIconWrapper>
      </DropdownButton>
      <DropdownMenu isOpen={isOpen}>
        {productsDropdownItems.map((item) => (
          <DropdownMenuItem key={item.key} onClick={() => openProduct(item.value)}>
            <Text type='body2normal' hoverColor='blue'>
              {item.value}
            </Text>
          </DropdownMenuItem>
        ))}
      </DropdownMenu>
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownButton = styled.button`
  border: none;
  background: none;
  padding: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  gap: 10px;
`;

const ChevronIconWrapper = styled.div<{ isOpen: boolean }>`
  margin-left: auto;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const DropdownMenu = styled.div<{ isOpen: boolean }>`
  list-style: none;
  border: 1px solid #ddd;
  border-radius: var(--Radius-m, 12px);
  background: white;
  position: absolute;
  top: 60px;
  width: 156px;
  padding: 8px;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.05), 0px 16px 20px 0px rgba(0, 0, 0, 0.24);
`;

const DropdownMenuItem = styled.div`
  display: flex;
  padding: var(--Spacing-sm, 12px) 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;

  &:hover {
    background-color: ${(props) => props.theme.colors.shadeGrey};
  }
`;

export default ProductsDropdown;
