import Button from 'components/atoms/Button/Button';
import Text from 'components/atoms/Text/Text';
import useWindowDimensions from 'hooks/useWindowDimensions';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { deviceBreakpoints } from 'Theme';

interface Props {
  title?: string | null;
  subtitle?: ReactNode[];
  description?: ReactNode[];
  image?: string;
}

const ImageSection: React.FC<Props> = ({ title, subtitle, description, image }) => {
  const { t } = useTranslation(['main']);
  const navigate = useNavigate();

  const { isMobile } = useWindowDimensions();

  const titleType = isMobile ? 'title2' : 'display1';
  const subtitleType = isMobile ? 'title3' : 'title2';
  const descriptionType = isMobile ? 'caption1' : 'body1normal';
  const buttonType = isMobile ? 'label2' : 'body1normal';
  const size = isMobile ? 'small' : 'large';

  return (
    <Container>
      <Image src={image} alt={'box'} />
      <ContentWrapper>
        <Text type={titleType} weight='bold' textColor='white'>
          {title}
        </Text>
        <Text type={subtitleType} weight='medium' textColor='white'>
          {subtitle}
        </Text>
        <Text type={descriptionType} textColor='white'>
          {description}
        </Text>
        <Button
          buttonType={'gradient-blue'}
          weight='bold'
          text={t('requestDemo')}
          onClickAction={() => navigate('/contact-us')}
          textColor='blue'
          size={size}
          textType={buttonType}
        />
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media only screen and ${deviceBreakpoints.mobile} {
    height: 75vh;
  }
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
`;

const ContentWrapper = styled.div`
  position: absolute;
  text-align: left;
  width: 1348px;
  z-index: 1;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: auto;
  gap: var(--Spacing-l, 28px);

  @media only screen and ${deviceBreakpoints.mobile} {
    width: 336px;
    align-items: center;
    text-align: center;
  }
`;

export default ImageSection;
