import Text from 'components/atoms/Text/Text';
import useWindowDimensions from 'hooks/useWindowDimensions';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { deviceBreakpoints } from 'Theme';

interface Props {
  image?: string;
  description?: ReactNode[];
}

const DesignSection: React.FC<Props> = ({ image, description }) => {
  const { isMobile } = useWindowDimensions();

  const textType = isMobile ? 'headline1' : 'title2';

  return (
    <Container>
      <BoxContainer>
        <Text type={textType} weight='medium' padding='10px'>
          {description}
        </Text>
        {image && <Image src={image} alt='Description Image' />}
      </BoxContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #f7f8f9;
  align-items: center;
  padding-top: 122px;
  padding-bottom: 80px;

  @media only screen and ${deviceBreakpoints.mobile} {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  max-width: 1348px;
  gap: 60px;
  margin: auto;

  @media only screen and ${deviceBreakpoints.mobile} {
    padding-bottom: 0;
    min-width: 336px;
    gap: 32px;
  }
`;

const Image = styled.img`
  width: 925px;
  height: 325px;
  display: block;
  margin-bottom: 0;

  @media only screen and ${deviceBreakpoints.mobile} {
    width: 336px;
    height: unset;
    max-height: 900px;
  }
`;

export default DesignSection;
