import React, { ReactNode } from 'react'; // Ensure React is imported
import { TranslationType } from 'utils/utils';

import useWindowDimensions from './useWindowDimensions';

// Define the hook
export default function useRenderParagraphs(paragraphs: TranslationType | null): ReactNode[] {
  const { isMobile } = useWindowDimensions();
  // Handle when paragraphs is an instance of TranslationType
  if (
    paragraphs &&
    typeof paragraphs === 'object' &&
    'Mobile' in paragraphs &&
    'Desktop' in paragraphs
  ) {
    const newParagraphs = isMobile ? paragraphs.Mobile : paragraphs.Desktop;
    if (Array.isArray(newParagraphs)) {
      return newParagraphs.map((para, index) => (
        <div key={index}>{para !== '' ? para : '\u00A0'}</div>
      ));
    } else {
      console.error('Expected newParagraphs to be an array, but got:', typeof newParagraphs);
      return [<div key={0}>Invalid content</div>];
    }
  }

  // Return a default message if paragraphs is invalid or null
  return [<div key={0}>No content available</div>];
}
