import React from 'react';

const DependableIcon: React.FC = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48' fill='none'>
      <g opacity='0.8'>
        <path
          d='M34.9844 11.9299V4.90303C34.9844 4.34056 34.5091 3.86523 33.9466 3.86523H11.9786C11.4162 3.86523 10.9408 4.34056 10.9408 4.90303V12.8885C10.9408 13.451 11.4162 13.9263 11.9786 13.9263C12.5411 13.9263 13.0164 13.451 13.0164 12.8885V5.8616H32.988V12.8885C32.988 13.451 33.4713 13.9263 34.0258 13.9263H35.0636V11.9299H34.9844Z'
          fill='#7F9AA3'
        />
        <path
          d='M45.1247 12.9677H41.9321H5.98952H2.79691C1.75912 12.9677 0.958984 13.7679 0.958984 14.8057V41.1625C0.958984 42.2003 1.75912 43.0004 2.79691 43.0004H45.1168C46.1546 43.0004 46.9547 42.2003 46.9547 41.1625V14.8136C46.9626 13.7679 46.0833 12.9677 45.1247 12.9677Z'
          fill='#7F9AA3'
        />
        <path
          d='M43.9284 17.919C42.8114 17.919 41.9321 17.0397 41.9321 15.9227V12.9677H5.98952L5.98952 15.9227C5.98952 17.0397 5.11016 17.919 3.99315 17.919H1.03821V37.8907H3.99315C5.11016 37.8907 5.98952 38.77 5.98952 39.887V42.842H41.9242V39.9662C41.9242 38.8492 42.8035 37.9699 43.9205 37.9699H46.8755V18.0062H43.9205V17.919H43.9284Z'
          fill='#3B4560'
        />
        <path
          d='M23.9648 17.919C29.4785 17.919 33.9466 22.3871 33.9466 27.9009C33.9466 33.4147 29.4785 37.8827 23.9648 37.8827C18.451 37.8827 13.9829 33.4147 13.9829 27.9009C13.975 22.395 18.451 17.919 23.9648 17.919Z'
          fill='#65B2FE'
        />
        <path
          d='M33.9466 27.9088H28.9161C28.9161 22.395 26.682 17.927 23.8855 17.927C29.4706 17.919 33.9466 22.395 33.9466 27.9088Z'
          fill='#469BE0'
        />
        <path
          d='M23.9648 27.9088H28.9953C28.9953 33.4226 26.7613 37.8907 23.9648 37.8907V27.9088Z'
          fill='#469BE0'
        />
        <path
          d='M19.0135 27.9088C19.0135 33.4226 21.2475 37.8907 24.044 37.8907C18.5302 37.8907 14.0621 33.4147 14.0621 27.9088H19.0135Z'
          fill='#469BE0'
        />
        <path
          d='M23.9648 17.919L23.9648 27.9009H18.9342C19.0134 22.395 21.1683 17.919 23.9648 17.919Z'
          fill='#469BE0'
        />
        <path
          d='M46.9626 37.8907V41.0833C46.9626 42.1211 46.1625 42.9212 45.1247 42.9212H41.9321V39.9662C41.9321 38.8492 42.8114 37.9699 43.9285 37.9699H46.9626V37.8907Z'
          fill='#7F9AA3'
        />
        <path
          d='M5.98952 39.9662V42.9212H2.79691C1.75912 42.9212 0.958984 42.1211 0.958984 41.0833V37.8907H3.91393C5.11016 37.8907 5.98952 38.8492 5.98952 39.9662Z'
          fill='#7F9AA3'
        />
        <path
          d='M5.98952 12.9677L5.98952 15.9227C5.98952 17.0397 5.11016 17.919 3.99315 17.919H1.03821V14.7264C1.03821 13.6886 1.83834 12.8885 2.87613 12.8885H5.98952L5.98952 12.9677Z'
          fill='#7F9AA3'
        />
        <path
          d='M46.9626 14.8057V17.9983H44.0077C42.8907 17.9983 42.0113 17.1189 42.0113 16.0019V13.0469H45.2039C46.0833 12.9677 46.9626 13.7679 46.9626 14.8057Z'
          fill='#7F9AA3'
        />
      </g>
    </svg>
  );
};

export default DependableIcon;
