import ContactUsPage from 'pages/ContactUs/ContactUs';
import RealIoT from 'pages/Products/RealIoT';
import RealSID from 'pages/Products/RealSID';
import RealSTT from 'pages/Products/RealSTT';
import RealTTS from 'pages/Products/RealTTS';
import { useEffect } from 'react';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
  useLocation,
} from 'react-router-dom';
import styled from 'styled-components';

import Footer from './components/organisms/Footer/Footer';
import Header from './components/organisms/Header/Header';
import AboutUsPage from './pages/AboutUs/AboutUs';
import MainPage from './pages/Main/Main';
import RealGPT from './pages/Products/RealGPT';
import { deviceBreakpoints } from './Theme';

const Layout = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <WholeWrapper>
      <Header />

      <BodyWrapper>
        <Outlet />
      </BodyWrapper>
      <Footer />
    </WholeWrapper>
  );
};

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <MainPage />,
      },
      {
        path: 'about-us',
        element: <AboutUsPage />,
      },
      {
        path: 'contact-us',
        element: <ContactUsPage />,
      },
      {
        path: '/products/real-gpt',
        element: <RealGPT />,
      },
      {
        path: '/products/real-stt',
        element: <RealSTT />,
      },
      {
        path: '/products/real-sid',
        element: <RealSID />,
      },
      {
        path: '/products/real-iot',
        element: <RealIoT />,
      },
      {
        path: '/products/real-TTS',
        element: <RealTTS />,
      },
      { path: '*', element: <Navigate to={'/'} replace /> },
    ],
  },
]);

const Routing = () => {
  return <RouterProvider router={router} />;
};

const WholeWrapper = styled.div``;

const BodyWrapper = styled.div`
  overflow-x: hidden;
  position: relative;

  @media only screen and ${deviceBreakpoints.mobile} {
    width: 100%;
  }
`;

export default Routing;
