import { ReactNode } from 'react';

export interface Feature {
  title: string;
  description: ReactNode[];
  image: string;
}

export interface Reason {
  title: string;
  description: ReactNode[];
  image: string;
}

export interface TranslationType {
  Desktop: string[];
  Mobile: string[];
}

export const handleRedirectToDemoSite = (link: string) => {
  window.open(link, '_blank')?.focus();
};
